import { Failure } from '../../../../core/domain/failures/failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { DataTableBloc } from '../../../../core/presentation/bloc/datatable_bloc/datatable_bloc';
import { CouponCategoryModel } from '../../domain/models/coupon_category_model';

export class CouponCategoryDataTableBloc extends DataTableBloc<CouponCategoryModel> {
    getUserStoresUseCase: IUseCase<DataTableResponse<CouponCategoryModel>, DataTableParams>;

    constructor(getUserStoresUseCase: IUseCase<DataTableResponse<CouponCategoryModel>, DataTableParams>) {
        super({ _type: 'Idle', page: 0, size: 10, filters: [], order: undefined, selection: [] });
        Object.setPrototypeOf(this, CouponCategoryDataTableBloc.prototype);
        this.getUserStoresUseCase = getUserStoresUseCase;
    }

    getCouponCategories = async (): Promise<void> => {
        const { page, size, filters, order, selection } = this.state;
        this.changeState({ _type: 'Loading', page, size, filters, order, selection });
        const result = await this.getUserStoresUseCase.execute({ page, size, filters, order });
        if (result instanceof Failure)
            return this.changeState({ _type: 'Failure', page, size, filters, order, selection, failure: result });
        this.changeState({
            _type: 'Loaded',
            page,
            size,
            filters,
            order,
            selection: [],
            data: result as DataTableResponse<CouponCategoryModel>,
        });
    };
}
