export interface PasswordRecoveryStrings {
    recover_password: string;
    password_recovery_email_email_error: string;
    password_recovery_email_required_error: string;
    password_recovery_email_exists_error: string;
    password_recovery_password_required_error: string;
    password_recovery_password_min_error: string;
    password_recovery_token_invalid_error: string;
    password_recovery_request_sent: string;
    password_recovery_request_sent_detail: string;
    password_recovery_success: string;
    password_recovery_success_detail: string;
    password_recovery_help: string;
}

export const passwordRecoveryStrings: PasswordRecoveryStrings = {
    recover_password: 'Recuperar contraseña',
    password_recovery_email_email_error: 'El campo email debe ser válido',
    password_recovery_email_required_error: 'El campo email es requerido',
    password_recovery_email_exists_error: 'No existe un usuario con el email indicado.',
    password_recovery_password_required_error: 'El campo Contraseña es requerido.',
    password_recovery_password_min_error: 'La contraseña debe tener más de 8 caracteres.',
    password_recovery_token_invalid_error: 'El enlace de recuperación es inválido.',
    password_recovery_request_sent: '¡Solicitud Enviada!',
    password_recovery_request_sent_detail:
        'Su solicitud ha sido enviada correctamente, recibirá un correo al email ingresado, con un enlace para poder realizar el cambio de contraseña.',
    password_recovery_success: '¡Cambio de Contraseña Exitoso!',
    password_recovery_success_detail:
        'Su contraseña ha sido cambiada correctamente, por favor intente acceder nuevamente.',
    password_recovery_help:
        'Ingrese el email con el que se registró y le enviaremos un enlace para poder recuperar o cambiar su contraseña.',
};
