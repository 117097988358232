import { AxiosInstance } from 'axios';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { CouponCategoryEntity } from '../entities/coupon_category_entity';
import { ICreateCouponCategoryValidations } from '../validations/create_coupon_category_validations';
import { IUpdateCouponCategoryValidations } from '../validations/update_coupon_category_validations';
import { ICouponCategoryDataSource } from './interfaces/coupon_category_datasource_interface';

export class AxiosCouponCategoryDataSource implements IAxiosDataSource, ICouponCategoryDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async createCouponCategory(couponCategory: CouponCategoryEntity): Promise<CouponCategoryEntity> {
        const { name } = couponCategory;
        try {
            const { data } = await this.axiosInstance.post('categories', {
                name,
            });
            return CouponCategoryEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<ICreateCouponCategoryValidations>(error));
        }
    }
    async updateCouponCategory(couponCategory: CouponCategoryEntity): Promise<CouponCategoryEntity> {
        try {
            const { id, name } = couponCategory;
            const { data } = await this.axiosInstance.post(`categories/${id}`, {
                name: name,
                _method: 'PUT',
            });
            return CouponCategoryEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<IUpdateCouponCategoryValidations>(error));
        }
    }
    async deleteCouponCategory(couponCategory: CouponCategoryEntity): Promise<void> {
        const { id } = couponCategory;
        try {
            return await this.axiosInstance.delete(`categories/${id}`);
        } catch (error) {
            return Promise.reject(httpToError<ICreateCouponCategoryValidations>(error));
        }
    }
    async getCouponCategories(datatableParams: DataTableParams): Promise<DataTableResponse<CouponCategoryEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`categories`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => CouponCategoryEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
}
