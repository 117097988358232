import { IModel } from '../../../../core/domain/interfaces/model_interface';

export type ResidueCategoryModelArgs = {
    id: number;
    name: string;
};
export class ResidueCategoryModel implements IModel {
    _type = 'ResidueCategoryModel';
    id: number;
    name: string;

    constructor({ id, name }: ResidueCategoryModelArgs) {
        this.id = id;
        this.name = name;
    }
}
