import { Failure } from '../../../../core/domain/failures/failure';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { ModalBloc } from '../../../../core/presentation/bloc/modal_bloc/modal_bloc';
import { CouponCategoryModel } from '../../domain/models/coupon_category_model';

export const emptyInputs: CouponCategoryModel = new CouponCategoryModel({
    id: -1,
    name: '',
});
export type CouponCategoryFormState = FormState<CouponCategoryModel, CouponCategoryModel>;
export class CouponCategoryFormBloc extends ModalBloc<CouponCategoryFormState> {
    createCouponCategoryUseCase: IUseCase<CouponCategoryModel, CouponCategoryModel>;
    updateCouponCategoryUseCase: IUseCase<CouponCategoryModel, CouponCategoryModel>;

    constructor(
        createCouponCategoryUseCase: IUseCase<CouponCategoryModel, CouponCategoryModel>,
        updateCouponCategoryUseCase: IUseCase<CouponCategoryModel, CouponCategoryModel>,
    ) {
        super({
            open: false,
            block: false,
            _type: 'Idle',
            inputs: emptyInputs,
            action: 'read',
        });
        this.createCouponCategoryUseCase = createCouponCategoryUseCase;
        this.updateCouponCategoryUseCase = updateCouponCategoryUseCase;
    }

    private async getCouponCategoryUseCase(action: string) {
        if (action === 'create') return await this.createCouponCategoryUseCase.execute(this.state.inputs);
        if (action === 'update') return await this.updateCouponCategoryUseCase.execute(this.state.inputs);
    }

    async submit(): Promise<void> {
        const { open, inputs, action } = this.state;
        if (action === 'read') return;
        this.changeState({ action, inputs, open, _type: 'Submitting', block: true });
        const result = await this.getCouponCategoryUseCase(action);
        if (result instanceof Failure) {
            this.changeState({ action, inputs, open, block: false, _type: 'Failure', failure: result });
            return;
        }
        if (result instanceof CouponCategoryModel)
            this.changeState({
                action,
                inputs,
                open,
                block: false,
                _type: 'Success',
                data: result as CouponCategoryModel,
            });
    }

    async open(): Promise<void> {
        this.openFor('create');
    }

    async openFor(action: 'read' | 'update' | 'create', data?: CouponCategoryModel) {
        if ((action === 'read' || action === 'update') && data !== undefined)
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: data,
                action,
            });
        if (action === 'create')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: emptyInputs,
                action,
            });
    }

    async changeInput(input: 'name', value: string) {
        this.changeState({
            ...this.state,
            _type: 'InputChanged',
            inputs: { ...this.state.inputs, [input]: value },
        });
    }
}
