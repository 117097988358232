import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueModel } from '../models/residue_model';
import { IResidueRepository } from '../repositories/residue_repository';

export class GetResidues implements IUseCase<DataTableResponse<ResidueModel>, DataTableParams> {
    residueRepository: IResidueRepository;

    constructor(residueRepository: IResidueRepository) {
        this.residueRepository = residueRepository;
    }
    execute(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueModel> | IFailure> {
        return this.residueRepository.getResidues(datatableParams);
    }
}
