import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { ResidueTypeModel } from '../../domain/models/residue_type_model';
import { IResidueTypeRepository } from '../../domain/repositories/residue_type_repository_interface';
import { IResidueTypeDataSource } from '../datasources/interfaces/residue_type_datasource_interface';
import { ResidueTypeEntity } from '../entities/residue_type_entity';
import { ICreateResidueTypeValidations } from '../validations/create_residue_type_validations';
import { IDeleteResidueTypeValidations } from '../validations/delete_residue_type_validations';
import { IUpdateResidueTypeValidations } from '../validations/update_residue_type_validations';

export class ResidueTypeRepository implements IResidueTypeRepository {
    datasource: IResidueTypeDataSource;

    constructor(datasource: IResidueTypeDataSource) {
        this.datasource = datasource;
    }

    async getResidueTypes(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueTypeModel> | IFailure> {
        try {
            const result: DataTableResponse<ResidueTypeEntity> = await this.datasource.getResidueTypes(datatableParams);
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure(error));
        }
    }
    async createResidueType(residueType: ResidueTypeModel): Promise<ResidueTypeModel | IFailure> {
        try {
            const entity: ResidueTypeEntity = await this.datasource.createResidueType(
                new ResidueTypeEntity(residueType),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<ICreateResidueTypeValidations>(error));
        }
    }
    async updateResidueType(residueType: ResidueTypeModel): Promise<ResidueTypeModel | IFailure> {
        try {
            const entity: ResidueTypeEntity = await this.datasource.updateResidueType(
                new ResidueTypeEntity(residueType),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<IUpdateResidueTypeValidations>(error));
        }
    }
    async deleteResidueType(residueType: ResidueTypeModel): Promise<void | IFailure> {
        try {
            return await this.datasource.deleteResidueType(new ResidueTypeEntity(residueType));
        } catch (error) {
            return Promise.resolve(errorToFailure<IDeleteResidueTypeValidations>(error));
        }
    }
}
