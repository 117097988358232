import { UserEntity } from '../../../../core/data/entities/user_entity';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { UserModelArgs } from '../../../../core/domain/models/user_model';
import { SellerModel, SellerModelArgs } from '../../domain/models/seller_model';
import { areAnyNil } from './../../../../core/app/utils/utils';

export class SellerEntity extends UserEntity {
    _type = 'SellerEntity';
    businessName: string;
    constructor(props: UserModelArgs & SellerModelArgs) {
        super(props);
        this.businessName = props.businessName;
    }
    toModel(): SellerModel {
        return new SellerModel(this);
    }

    static fromObject(obj: any): SellerEntity {
        const userEntity: UserEntity = super.fromObject(obj);
        const { business_name } = obj;
        if (areAnyNil([business_name])) {
            throw new ConvertionError(JSON.stringify(obj), 'SellerEntity');
        }
        return new SellerEntity({ ...userEntity, businessName: business_name });
    }
}
