import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { AdministratorModel } from '../models/administrator_model';
import { IAdministratorRepository } from '../repositories/administrator_repository_interface';

export class GetAdministrators implements IUseCase<DataTableResponse<AdministratorModel>, DataTableParams> {
    administratorRepository: IAdministratorRepository;

    constructor(administratorRepository: IAdministratorRepository) {
        this.administratorRepository = administratorRepository;
    }

    execute(datatableParams: DataTableParams): Promise<DataTableResponse<AdministratorModel> | IFailure> {
        return this.administratorRepository.getAdministrators(datatableParams);
    }
}
