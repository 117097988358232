import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { CouponCategoryModel } from '../../domain/models/coupon_category_model';

export class CouponCategoryEntity extends CouponCategoryModel implements IEntity {
    _type = 'ResidueCategoryEntity';

    toModel(): CouponCategoryModel {
        return new CouponCategoryModel(this);
    }

    static fromObject(obj: any): CouponCategoryEntity {
        const { id, name } = obj;
        if (areAnyNil([id, name])) {
            throw new ConvertionError(JSON.stringify(obj), 'CouponCategoryEntity');
        }
        return new CouponCategoryEntity({
            id,
            name,
        });
    }
}
