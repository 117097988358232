import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        delete: {
            color: theme.colors.danger,
        },
        activate: {
            color: theme.colors.success,
        },
        actions: {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);
