import { IModel } from '../../../../core/domain/interfaces/model_interface';
import { ResidueCategoryModel } from '../../../residue_categories/domain/models/residue_category_model';
import { ResidueTypeModel } from '../../../residue_types/domain/models/residue_type_model';

export type ResidueModelArgs = {
    id: number;
    name: string;
    barcode: string;
    image?: string | undefined | File;
    residueType: ResidueTypeModel;
    residueCategory: ResidueCategoryModel;
};

export class ResidueModel implements IModel {
    _type = 'ResidueModel';
    id: number;
    name: string;
    barcode: string;
    image?: string | undefined | File;
    residueType: ResidueTypeModel;
    residueCategory: ResidueCategoryModel;

    constructor({ id, name, image, barcode, residueType, residueCategory }: ResidueModelArgs) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.barcode = barcode;
        this.residueType = residueType;
        this.residueCategory = residueCategory;
    }
}
