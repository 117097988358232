export interface AdministratorStrings {
    administrator: string;
    administrators: string;
    administratorCreate: string;
    administratorRead: string;
    administratorActivate: string;
    administratorDeactivate: string;
    administratorName: string;
    administratorSurname: string;
    administratorUsername: string;
    administratorIdentificationNumber: string;
    administratorEmail: string;
    administratorCreateSuccessful: string;
    administratorStatus: string;
    administratorStatusChanged: string;

    //Errors
    administrator_id_exists_error: string;
    administrator_name_required_error: string;
    administrator_surname_required_error: string;

    administrator_username_required_error: string;
    administrator_username_unique_error: string;
    administrator_email_required_error: string;
    administrator_email_unique_error: string;
    administrator_email_email_error: string;
    administrator_identificationNumber_required_error: string;
    administrator_identificationNumber_numeric_error: string;
    administrator_identificationNumber_min_error: string;
    administrator_toggleActiveUser_exist_user_error: string;
    administrator_toggleActiveUser_user_active_error: string;
    administrator_createUserAdministrator_exist_user_error: string;
    administrator_createUserAdministrator_user_active_error: string;
    administrator_id_notin_error: string;
}

export const administratorStrings: AdministratorStrings = {
    administrator: 'Administrador',
    administrators: 'Administradores',
    administratorCreate: 'Crear',
    administratorRead: 'Ver',
    administratorActivate: 'Activar',
    administratorDeactivate: 'Desactivar',
    administratorName: 'Nombre',
    administratorSurname: 'Apellido',
    administratorUsername: 'Usuario',
    administratorIdentificationNumber: 'DNI',
    administratorEmail: 'Email',
    administratorStatus: 'Estado',
    administratorStatusChanged: 'El estado ha sido cambiado exitosamente',
    administratorCreateSuccessful: 'El Usuario Administrador ha sido creado correctamente',

    administrator_id_exists_error: 'Error: El usuario seleccionado no existe.',
    administrator_name_required_error: 'El campo Nombre es requerido.',
    administrator_surname_required_error: 'El campo Apellido es requerido.',
    administrator_email_required_error: 'El campo Email es requerido.',
    administrator_email_unique_error: 'El Email ya ha sido usado.',
    administrator_email_email_error: 'El campo Email es inválido',
    administrator_identificationNumber_required_error: 'El campo DNI es requerido.',
    administrator_identificationNumber_numeric_error: 'El campo DNI debe ser un número.',
    administrator_identificationNumber_min_error: 'El campo DNI debe tener al menos 8 números',
    administrator_toggleActiveUser_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    administrator_toggleActiveUser_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    administrator_createUserAdministrator_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    administrator_createUserAdministrator_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    administrator_id_notin_error: 'Error: No puede habilitar | deshabilitar su propia cuenta.',
    administrator_username_required_error: 'El campo Usuario es requerido',
    administrator_username_unique_error: 'El Usuario ya esta siendo usado.',
};
