import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueTypeModel } from '../models/residue_type_model';
import { IResidueTypeRepository } from '../repositories/residue_type_repository_interface';

export class CreateResidueType implements IUseCase<ResidueTypeModel, ResidueTypeModel> {
    residueTypeRespository: IResidueTypeRepository;

    constructor(residueTypeRespository: IResidueTypeRepository) {
        this.residueTypeRespository = residueTypeRespository;
    }

    async execute(residueType: ResidueTypeModel): Promise<ResidueTypeModel | IFailure> {
        return this.residueTypeRespository.createResidueType(residueType);
    }
}
