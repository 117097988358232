import { Button, Grid, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { UploadButton } from '../../../../../core/presentation/components/upload_button/upload_button';
import { useResidueTypeFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { ResidueTypeFormBloc, ResidueTypeFormState } from '../../blocs/residue_type_form_bloc';
import { useStyles } from './styles';

const server_url = process.env.REACT_APP_SERVER_URL;

export const ResidueTypeForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: ResidueTypeFormBloc = useResidueTypeFormBloc();
    const classes = useStyles();

    useEffect(() => {
        const showSnackBar = (state: ModalState & ResidueTypeFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'residueType'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.createResidueType)
                            enqueueSnackbar(getErrorString('createResidueType', state, 'residueType'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.updateResidueType)
                            enqueueSnackbar(getErrorString('updateResidueType', state, 'residueType'), {
                                variant: 'error',
                            });
                        return;
                    }
                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }

                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.residueTypeCreateSuccessful, { variant: 'success' });
                if (state._type === 'Success' && state.action === 'update')
                    enqueueSnackbar(Language.strings.residueTypeEditSuccessful, { variant: 'success' });
            }
        };
        bloc.subscribe(showSnackBar);

        return () => {
            bloc.unsubscribe(showSnackBar);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string | number | File = event.target.value;
        const input = event.target.name;
        if (input === 'image' && event.target.files !== null && event.target.files?.length > 0) {
            value = event.target.files[0];
        }
        bloc.changeInput(input as 'name' | 'amount' | 'kg' | 'water' | 'energy' | 'co2' | 'km' | 'image', value);
    };

    const handleDescriptionChanged = (type: 'good' | 'bad', value: string, current: string) => {
        let newValue = (current !== '' && JSON.parse(current)) || {
            good: '',
            bad: '',
        };
        newValue[type] = value;
        newValue = JSON.stringify(newValue);
        bloc.changeInput('description', newValue);
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ModalState & ResidueTypeFormState) => {
                const { action, inputs } = state;
                const title =
                    (action && action === 'read' && Language.strings.residueTypeRead) ||
                    (action === 'create' && Language.strings.residueTypeCreate) ||
                    (action === 'update' && Language.strings.residueTypeUpdate) ||
                    '';
                const descriptions: any = (inputs.description !== '' && JSON.parse(inputs.description)) || {
                    good: '',
                    bad: '',
                };
                let imageUrl = '';
                if (inputs.image) {
                    if (inputs.image instanceof File) {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(inputs.image);
                        imageUrl = URL.createObjectURL(inputs.image);
                    } else imageUrl = `${server_url}${inputs.image}`;
                }

                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <FullScreenDialog
                        title={`${title} ${Language.strings.residueResidueType}`}
                        open={state.open}
                        disableClose={state.block}
                        actions={
                            state.action !== 'read' ? (
                                <Button
                                    color="inherit"
                                    onClick={handleSubmit}
                                    disabled={state._type === 'Submitting'}
                                    startIcon={<SaveIcon />}
                                >
                                    {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                </Button>
                            ) : undefined
                        }
                        content={
                            <Grid container item xs={12} direction="column" className={`${classes.container}`}>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.residueTypeName}
                                        name="name"
                                        value={inputs.name}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.name
                                        }
                                        helperText={getErrorString('name', state, 'residueType')}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid container className={classes.pb} justify="space-between" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            name="description-good"
                                            multiline
                                            rowsMax={6}
                                            rows={3}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeDescriptionGood}
                                            value={descriptions.good}
                                            onChange={
                                                state.action === 'read'
                                                    ? undefined
                                                    : (e) =>
                                                          handleDescriptionChanged(
                                                              'good',
                                                              e.target.value,
                                                              inputs.description,
                                                          )
                                            }
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.description
                                            }
                                            helperText={getErrorString('description', state, 'residueType')}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            name="description-bad"
                                            multiline
                                            rowsMax={6}
                                            rows={3}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeDescriptionBad}
                                            value={descriptions.bad}
                                            onChange={
                                                state.action === 'read'
                                                    ? undefined
                                                    : (e) =>
                                                          handleDescriptionChanged(
                                                              'bad',
                                                              e.target.value,
                                                              inputs.description,
                                                          )
                                            }
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.description
                                            }
                                            helperText={getErrorString('description', state, 'residueType')}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className={classes.pb} justify="space-between" spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="amount"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeAmount}
                                            value={inputs.amount}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.amount
                                            }
                                            helperText={getErrorString('amount', state, 'residueType')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="kg"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeKg}
                                            value={inputs.kg}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.kg
                                            }
                                            helperText={getErrorString('kg', state, 'residueType')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="water"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeWater}
                                            value={inputs.water}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.water
                                            }
                                            helperText={getErrorString('water', state, 'residueType')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className={classes.pb} justify="space-between" spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="energy"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeEnergy}
                                            value={inputs.energy}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.energy
                                            }
                                            helperText={getErrorString('energy', state, 'residueType')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="co2"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeCo2}
                                            value={inputs.co2}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.co2
                                            }
                                            helperText={getErrorString('co2', state, 'residueType')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            name="km"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label={Language.strings.residueTypeKm}
                                            value={inputs.km}
                                            onChange={state.action === 'read' ? undefined : handleInputChanged}
                                            error={
                                                state._type === 'Failure' &&
                                                state.failure instanceof ValidationFailure &&
                                                state.failure.fails.km
                                            }
                                            helperText={getErrorString('km', state, 'residueType')}
                                        />
                                    </Grid>
                                </Grid>
                                {action !== 'read' && (
                                    <>
                                        <Grid item className={`${classes.pb} ${classes.center}`}>
                                            <UploadButton
                                                name="image"
                                                color="secondary"
                                                variant="outlined"
                                                onChange={handleInputChanged}
                                                label={Language.strings.residueTypeImage}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {imageUrl !== '' ? (
                                    <Grid item className={`${classes.pb} ${classes.center}`}>
                                        <div className={`${classes.imageViewport} ${classes.center}`}>
                                            <img src={imageUrl} />
                                        </div>
                                    </Grid>
                                ) : null}
                            </Grid>
                        }
                        onClose={() => bloc.close()}
                    />
                );
            }}
        />
    );
};
