import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { SellerModel } from '../models/seller_model';
import { ISellerRepository } from '../repositories/seller_repository_interface';

export class GetSellers implements IUseCase<DataTableResponse<SellerModel>, DataTableParams> {
    sellerRepository: ISellerRepository;

    constructor(sellerRepository: ISellerRepository) {
        this.sellerRepository = sellerRepository;
    }

    execute(datatableParams: DataTableParams): Promise<DataTableResponse<SellerModel> | IFailure> {
        return this.sellerRepository.getSellers(datatableParams);
    }
}
