/* istanbul ignore file */

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosAdministratorDataSource } from '../../../features/administrators/data/datasources/axios_administrator_datasource';
import { AdministratorRepository } from '../../../features/administrators/data/repositories/administrator_repository';
import { AdministratorModel } from '../../../features/administrators/domain/models/administrator_model';
import { IAdministratorRepository } from '../../../features/administrators/domain/repositories/administrator_repository_interface';
import { AdministratorDataTableBloc } from '../../../features/administrators/presentation/blocs/administrator_datatable_bloc';
import { AdministratorFormBloc } from '../../../features/administrators/presentation/blocs/administrator_form_bloc';
import { AxiosAuthenticationDataSource } from '../../../features/authentication/data/datasources/axios_authentication_datasource';
import { ICacheDataSource } from '../../../features/authentication/data/datasources/interfaces/cache_datasource_interface';
import { SessionStorageCacheDataSource } from '../../../features/authentication/data/datasources/session_storage_cache_datasource';
import { AuthenticationRepository } from '../../../features/authentication/data/repositories/authentication_repository';
import { ChangePassword } from '../../../features/authentication/domain/usecases/change_password/change_password';
import { GetAuthenticatedUser } from '../../../features/authentication/domain/usecases/get_authenticated_user/get_authenticated_user';
import { Login } from '../../../features/authentication/domain/usecases/login/login';
import { Logout } from '../../../features/authentication/domain/usecases/logout/logout';
import { RequestPasswordRecovery } from '../../../features/authentication/domain/usecases/request_recovery/request_recovery';
import { AuthenticationBloc } from '../../../features/authentication/presentation/blocs/authentication_bloc/authentication_bloc';
import { LoginPageBloc } from '../../../features/authentication/presentation/blocs/login_page_bloc/login_page_bloc';
import { PasswordRecoveryBloc } from '../../../features/authentication/presentation/blocs/password_recovery_bloc/password_recovery_bloc';
import { AxiosCouponCategoryDataSource } from '../../../features/coupon_categories/data/datasources/axios_coupon_category_datasource';
import { CouponCategoryRepository } from '../../../features/coupon_categories/data/repositories/coupon_category_repository';
import { CouponCategoryModel } from '../../../features/coupon_categories/domain/models/coupon_category_model';
import { ICouponCategoryRepository } from '../../../features/coupon_categories/domain/repositories/coupon_category_repository_interface';
import { CreateCouponCategory } from '../../../features/coupon_categories/domain/usecases/create_coupon_category';
import { DeleteCouponCategory } from '../../../features/coupon_categories/domain/usecases/delete_coupon_category';
import { GetCouponCategories } from '../../../features/coupon_categories/domain/usecases/get_coupon_categories';
import { UpdateCouponCategory } from '../../../features/coupon_categories/domain/usecases/update_coupon_category';
import { CouponCategoryDataTableBloc } from '../../../features/coupon_categories/presentation/blocs/coupon_category_datatable_bloc';
import { CouponCategoryFormBloc } from '../../../features/coupon_categories/presentation/blocs/coupon_category_form_bloc';
import { AxiosResidueDataSource } from '../../../features/residues/data/datasources/axios_residue_datasource';
import { ResidueRepository } from '../../../features/residues/data/repositories/residue_repository';
import { ResidueModel } from '../../../features/residues/domain/models/residue_model';
import { IResidueRepository } from '../../../features/residues/domain/repositories/residue_repository';
import { CreateResidue } from '../../../features/residues/domain/usecases/create_residue';
import { DeleteResidue } from '../../../features/residues/domain/usecases/delete_residue';
import { GetResidues } from '../../../features/residues/domain/usecases/get_residues';
import { UpdateResidue } from '../../../features/residues/domain/usecases/update_residue';
import { ResiduesDataTableBloc } from '../../../features/residues/presentation/blocs/residues_datatable_bloc';
import { ResidueFormBloc } from '../../../features/residues/presentation/blocs/residue_form_bloc';
import { AxiosResidueCategoryDataSource } from '../../../features/residue_categories/data/datasources/axios_residue_category_datasource';
import { ResidueCategoryRepository } from '../../../features/residue_categories/data/repositories/residue_category_repository';
import { ResidueCategoryModel } from '../../../features/residue_categories/domain/models/residue_category_model';
import { IResidueCategoryRepository } from '../../../features/residue_categories/domain/repositories/residue_category_repository_interface';
import { CreateResidueCategory } from '../../../features/residue_categories/domain/usecases/create_residue_category';
import { DeleteResidueCategory } from '../../../features/residue_categories/domain/usecases/delete_residue_category';
import { GetResidueCategories } from '../../../features/residue_categories/domain/usecases/get_residue_categories';
import { UpdateResidueCategory } from '../../../features/residue_categories/domain/usecases/update_residue_category';
import { ResidueCategoryDataTableBloc } from '../../../features/residue_categories/presentation/blocs/residue_category_datatable_bloc';
import { ResidueCategoryFormBloc } from '../../../features/residue_categories/presentation/blocs/residue_category_form_bloc';
import { AxiosResidueTypeDataSource } from '../../../features/residue_types/data/datasources/axios_residue_type_datasource';
import { ResidueTypeRepository } from '../../../features/residue_types/data/repositories/residue_type_repository';
import { ResidueTypeModel } from '../../../features/residue_types/domain/models/residue_type_model';
import { IResidueTypeRepository } from '../../../features/residue_types/domain/repositories/residue_type_repository_interface';
import { CreateResidueType } from '../../../features/residue_types/domain/usecases/create_residue_type';
import { DeleteResidueType } from '../../../features/residue_types/domain/usecases/delete_residue_type';
import {
    GetResidueTypes,
    GetResidueTypes as GetResidueTypesDataTable,
} from '../../../features/residue_types/domain/usecases/get_residue_types';
import { UpdateResidueType } from '../../../features/residue_types/domain/usecases/update_residue_type';
import { ResidueTypesDataTableBloc } from '../../../features/residue_types/presentation/blocs/residue_type_datatable_bloc';
import { ResidueTypeFormBloc } from '../../../features/residue_types/presentation/blocs/residue_type_form_bloc';
import { AxiosSellerDataSource } from '../../../features/sellers/data/datasources/axios_seller_datasource';
import { SellerRepository } from '../../../features/sellers/data/repositories/seller_repository';
import { SellerModel } from '../../../features/sellers/domain/models/seller_model';
import { ISellerRepository } from '../../../features/sellers/domain/repositories/seller_repository_interface';
import { CreateSeller } from '../../../features/sellers/domain/usecases/create_seller';
import { GetSellers } from '../../../features/sellers/domain/usecases/get_sellers';
import { ToggleSeller } from '../../../features/sellers/domain/usecases/toggle_seller';
import { SellerDataTableBloc } from '../../../features/sellers/presentation/blocs/seller_datatable_bloc';
import { SellerFormBloc } from '../../../features/sellers/presentation/blocs/seller_form_bloc';
import { DeletionBloc } from '../../presentation/bloc/deletion_bloc/deletion_bloc';
import { IDateFormatter } from '../../presentation/formaters/interfaces/date_formatter_interface';
import { MomentSpanishDateFormatter } from '../../presentation/formaters/moment_date_formatter';
import { Language } from '../../presentation/strings/LanguageManager';
import { CreateAdministrator } from './../../../features/administrators/domain/usecases/create_administrator';
import { GetAdministrators } from './../../../features/administrators/domain/usecases/get_administrators';
import { ToggleAdministrator } from './../../../features/administrators/domain/usecases/toggle_administrator';

const api_url = process.env.REACT_APP_API_URL;

class DependencyInjector {
    private static axiosInstance?: AxiosInstance = undefined;

    static async loadAsyncDependencies() {
        Language.set('es');
        await this.createAxiosInstance(new SessionStorageCacheDataSource());
    }

    static async createAxiosInstance(cacheDataSource: ICacheDataSource, config?: AxiosRequestConfig): Promise<void> {
        const defaultHeaders = { 'Access-Control-Allow-Origin': true };
        if (!this.axiosInstance) {
            const token = await cacheDataSource.get('token');
            this.axiosInstance = axios.create({
                ...config,
                baseURL: api_url,
                headers: token ? { ...defaultHeaders, Authorization: 'Bearer ' + token } : defaultHeaders,
            });
        }
        this.axiosInstance.defaults = { ...this.axiosInstance.defaults, timeout: 180000, ...config };
    }

    private static authenticationRepository() {
        const cacheDataSource = new SessionStorageCacheDataSource();
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const apiDataSource = new AxiosAuthenticationDataSource(axios);
        return new AuthenticationRepository(apiDataSource, cacheDataSource);
    }

    static loginPageBloc(): LoginPageBloc {
        const usecase = new Login(this.authenticationRepository());
        return new LoginPageBloc(usecase);
    }
    static authBloc(): AuthenticationBloc {
        const getAuthenticatedUser = new GetAuthenticatedUser(this.authenticationRepository());
        const logout = new Logout(this.authenticationRepository());
        return new AuthenticationBloc(getAuthenticatedUser, logout);
    }
    static passwordRecoveryBloc(): PasswordRecoveryBloc {
        const requestPasswordRecoveryUseCase = new RequestPasswordRecovery(this.authenticationRepository());
        const changePasswordUseCase = new ChangePassword(this.authenticationRepository());
        return new PasswordRecoveryBloc({ requestPasswordRecoveryUseCase, changePasswordUseCase });
    }

    static residueTypeRepository(): IResidueTypeRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosResidueTypeDataSource(axios);
        return new ResidueTypeRepository(datasource);
    }

    static residueTypeFormBloc(): ResidueTypeFormBloc {
        const createResidueTypeUseCase = new CreateResidueType(this.residueTypeRepository());
        const updateResidueTypeUseCase = new UpdateResidueType(this.residueTypeRepository());
        return new ResidueTypeFormBloc(createResidueTypeUseCase, updateResidueTypeUseCase);
    }
    static residueTypesDataTableBloc(): ResidueTypesDataTableBloc {
        const getResidueTypesUseCase = new GetResidueTypesDataTable(this.residueTypeRepository());
        return new ResidueTypesDataTableBloc(getResidueTypesUseCase);
    }

    static residueTypeDeletionBloc(): DeletionBloc<ResidueTypeModel> {
        const deleteUseCase = new DeleteResidueType(this.residueTypeRepository());
        return new DeletionBloc<ResidueTypeModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    static residueCategoryRepository(): IResidueCategoryRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosResidueCategoryDataSource(axios);
        return new ResidueCategoryRepository(datasource);
    }

    static residueCategoryFormBloc(): ResidueCategoryFormBloc {
        const createResidueCategoryUseCase = new CreateResidueCategory(this.residueCategoryRepository());
        const updateResidueCategoryUseCase = new UpdateResidueCategory(this.residueCategoryRepository());
        return new ResidueCategoryFormBloc(createResidueCategoryUseCase, updateResidueCategoryUseCase);
    }
    static residueCategoryDataTableBloc(): ResidueCategoryDataTableBloc {
        const getResidueCategorysUseCase = new GetResidueCategories(this.residueCategoryRepository());
        return new ResidueCategoryDataTableBloc(getResidueCategorysUseCase);
    }

    static residueCategoryDeletionBloc(): DeletionBloc<ResidueCategoryModel> {
        const deleteUseCase = new DeleteResidueCategory(this.residueCategoryRepository());
        return new DeletionBloc<ResidueCategoryModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    static residueRepository(): IResidueRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosResidueDataSource(axios);
        return new ResidueRepository(datasource);
    }

    static residueFormBloc(): ResidueFormBloc {
        const createResidueUseCase = new CreateResidue(this.residueRepository());
        const updateResidueUseCase = new UpdateResidue(this.residueRepository());
        const getResidueTypesUseCase = new GetResidueTypes(this.residueTypeRepository());
        const getResidueCategoryUseCase = new GetResidueCategories(this.residueCategoryRepository());
        return new ResidueFormBloc(
            createResidueUseCase,
            updateResidueUseCase,
            getResidueTypesUseCase,
            getResidueCategoryUseCase,
        );
    }
    static residueDataTableBloc(): ResiduesDataTableBloc {
        const getResiduesUseCase = new GetResidues(this.residueRepository());
        return new ResiduesDataTableBloc(getResiduesUseCase);
    }

    static residueDeletionBloc(): DeletionBloc<ResidueModel> {
        const deleteUseCase = new DeleteResidue(this.residueRepository());
        return new DeletionBloc<ResidueModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    static dateFormatter(): IDateFormatter {
        return new MomentSpanishDateFormatter();
    }

    static couponCategoryRepository(): ICouponCategoryRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosCouponCategoryDataSource(axios);
        return new CouponCategoryRepository(datasource);
    }

    static couponCategoryFormBloc(): CouponCategoryFormBloc {
        const createCouponCategoryUseCase = new CreateCouponCategory(this.couponCategoryRepository());
        const updateCouponCategoryUseCase = new UpdateCouponCategory(this.couponCategoryRepository());
        return new CouponCategoryFormBloc(createCouponCategoryUseCase, updateCouponCategoryUseCase);
    }
    static couponCategoryDataTableBloc(): CouponCategoryDataTableBloc {
        const getCouponCategorysUseCase = new GetCouponCategories(this.couponCategoryRepository());
        return new CouponCategoryDataTableBloc(getCouponCategorysUseCase);
    }

    static couponCategoryDeletionBloc(): DeletionBloc<CouponCategoryModel> {
        const deleteUseCase = new DeleteCouponCategory(this.couponCategoryRepository());
        return new DeletionBloc<CouponCategoryModel>(deleteUseCase, { _type: 'Idle', model: undefined });
    }

    static administratorRepository(): IAdministratorRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosAdministratorDataSource(axios);
        return new AdministratorRepository(datasource);
    }
    static administratorFormBloc(): AdministratorFormBloc {
        const createCouponCategoryUseCase = new CreateAdministrator(this.administratorRepository());
        return new AdministratorFormBloc(createCouponCategoryUseCase);
    }
    static administratorDataTableBloc(): AdministratorDataTableBloc {
        const getUseCase = new GetAdministrators(this.administratorRepository());
        return new AdministratorDataTableBloc(getUseCase);
    }
    static administratorToggleBloc(): DeletionBloc<AdministratorModel> {
        const toggleUseCase = new ToggleAdministrator(this.administratorRepository());
        return new DeletionBloc<AdministratorModel>(toggleUseCase, { _type: 'Idle', model: undefined });
    }

    static sellerRepository(): ISellerRepository {
        const axios: AxiosInstance = this.axiosInstance as AxiosInstance;
        const datasource = new AxiosSellerDataSource(axios);
        return new SellerRepository(datasource);
    }
    static sellerFormBloc(): SellerFormBloc {
        const createCouponCategoryUseCase = new CreateSeller(this.sellerRepository());
        return new SellerFormBloc(createCouponCategoryUseCase);
    }
    static sellerDataTableBloc(): SellerDataTableBloc {
        const getUseCase = new GetSellers(this.sellerRepository());
        return new SellerDataTableBloc(getUseCase);
    }
    static sellerToggleBloc(): DeletionBloc<SellerModel> {
        const toggleUseCase = new ToggleSeller(this.sellerRepository());
        return new DeletionBloc<SellerModel>(toggleUseCase, { _type: 'Idle', model: undefined });
    }
}
export default DependencyInjector;
