import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    administratorDataTableBlocContext,
    administratorDeletionContext,
    administratorFormBlocContext,
} from '../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../core/presentation/utils/get_error_string';
import { AdministratorDataTable } from '../components/administrator_datatable/administrator_datatable';
import { AdministratorForm } from '../components/administrator_form/administrator_form';

export const AdministratorsPage = () => {
    const administratorDataTableBloc = di.administratorDataTableBloc();
    const administratorFormBloc = di.administratorFormBloc();
    const administratorToggleBloc = di.administratorToggleBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (administratorDataTableBloc.state._type === 'Loaded') {
            administratorToggleBloc.askConfirmation(administratorDataTableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        administratorToggleBloc.delete();
        administratorDataTableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        administratorToggleBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (administratorDataTableBloc.state._type === 'Loaded') {
            administratorFormBloc.openFor('read', administratorDataTableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.toggleActiveUser)
                enqueueSnackbar(getErrorString('toggleActiveUser', { _type: 'Failure', failure }, 'administrator'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'administrator'), {
                    variant: 'error',
                });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.loggedOut, { variant: 'error' });
            return;
        }

        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.administratorStatusChanged, { variant: 'success' });
    };

    const handleCreate = () => {
        administratorFormBloc.openFor('create');
    };

    return (
        <>
            <administratorFormBlocContext.Provider value={administratorFormBloc}>
                <administratorDeletionContext.Provider value={administratorToggleBloc}>
                    <administratorDataTableBlocContext.Provider value={administratorDataTableBloc}>
                        <AdministratorDataTable
                            onRead={handleRead}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </administratorDataTableBlocContext.Provider>
                    <DeletionConfirmationModal
                        bloc={administratorToggleBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </administratorDeletionContext.Provider>
                <AdministratorForm />
            </administratorFormBlocContext.Provider>
        </>
    );
};
