import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { ResidueEntity } from '../entities/residue_entity';
import { ICreateResidueValidations } from '../validations/create_residue_validations';
import { IDeleteResidueValidations } from '../validations/delete_residue_validations';
import { IResidueDataSource } from './interfaces/residue_datasource_interface';

export class AxiosResidueDataSource implements IAxiosDataSource, IResidueDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getResidues(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`residues`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => ResidueEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
    async createResidue(residue: ResidueEntity): Promise<ResidueEntity> {
        const formData = this.entityToFormData(residue);

        try {
            const { data } = await this.axiosInstance.post('residues', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            return ResidueEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICreateResidueValidations>({
                        ...error.response.data,
                        residueType: error.response.data.residue_type_id,
                        residueCategory: error.response.data.residue_category_id,
                    }),
                );
            }
            return Promise.reject(httpToError<ICreateResidueValidations>(error));
        }
    }
    async updateResidue(residue: ResidueEntity): Promise<ResidueEntity> {
        const formData = this.entityToFormData(residue);
        formData.append('_method', 'PUT');
        try {
            const { data } = await this.axiosInstance.post(`residues/${residue.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            return ResidueEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICreateResidueValidations>({
                        ...error.response.data,
                        residueType: error.response.data.residue_type_id,
                        residueCategory: error.response.data.residue_category_id,
                    }),
                );
            }
            return Promise.reject(httpToError<IDeleteResidueValidations>(error));
        }
    }
    async deleteResidue(residue: ResidueEntity): Promise<void> {
        const { id } = residue;
        try {
            return await this.axiosInstance.delete(`residues/${id}`);
        } catch (error) {
            return Promise.reject(httpToError<IDeleteResidueValidations>(error));
        }
    }

    /* istanbul ignore next */
    private entityToFormData(residue: ResidueEntity): FormData {
        const { name, image, barcode, residueType, residueCategory } = residue;
        const formData = new FormData();

        formData.append('name', name);
        if (image) formData.append('image', image);
        if (barcode) formData.append('barcode', barcode);
        formData.append('residue_type_id', residueType.id.toString());
        formData.append('residue_category_id', residueCategory.id.toString());

        return formData;
    }
}
