import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { ResidueCategoryModel } from '../../domain/models/residue_category_model';

export class ResidueCategoryEntity extends ResidueCategoryModel implements IEntity {
    _type = 'ResidueCategoryEntity';

    toModel(): ResidueCategoryModel {
        return new ResidueCategoryModel(this);
    }

    static fromObject(obj: any): ResidueCategoryEntity {
        const { id, name } = obj;
        if (areAnyNil([id, name])) {
            throw new ConvertionError(JSON.stringify(obj), 'ResidueCategoryEntity');
        }
        return new ResidueCategoryEntity({
            id,
            name,
        });
    }
}
