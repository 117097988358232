import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { ResidueTypeModel, ResidueTypeModelArgs } from '../../domain/models/residue_type_model';

export class ResidueTypeEntity extends ResidueTypeModel implements IEntity {
    _type = 'ResidueTypeEntity';

    constructor({ id, name, amount, kg, water, energy, co2, km, image, description }: ResidueTypeModelArgs) {
        super({ id, name, amount, kg, water, energy, co2, km, image, description });
    }

    toModel(): ResidueTypeModel {
        return new ResidueTypeModel(this);
    }

    static fromObject(obj: any): ResidueTypeEntity {
        try {
            const { id, name, amount, kg, water, energy, co2, km, image, description } = obj;
            if (areAnyNil([id, name, amount, kg, water, energy, co2, km, image, description])) {
                throw new ConvertionError(JSON.stringify(obj), 'ResidueTypeEntity');
            }
            return new ResidueTypeEntity({
                id,
                name,
                amount,
                kg,
                water,
                energy,
                co2,
                km,
                image,
                description,
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ResidueTypeEntity');
        }
    }
}
