import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import { administratorStrings, AdministratorStrings } from './ES/administrator';
import { couponCategoryStrings, CouponCategoryStrings } from './ES/coupon_category';
import { dataTableStrings, DataTableStrings } from './ES/datatable';
import { globalStrings, GlobalStrings } from './ES/global';
import { LoginStrings, loginStrings } from './ES/login';
import { PasswordRecoveryStrings, passwordRecoveryStrings } from './ES/password_recovery';
import { residueStrings, ResidueStrings } from './ES/residue';
import { residueCategoryStrings, ResidueCategoryStrings } from './ES/residue_category';
import { residueTypeStrings, ResidueTypeStrings } from './ES/residue_types';
import { SellerStrings, sellerStrings } from './ES/seller';

export class Language {
    static set = (key: 'es') => {
        Language.strings.setLanguage(key);
    };
    static strings: LocalizedStringsMethods &
        GlobalStrings &
        LoginStrings &
        DataTableStrings &
        PasswordRecoveryStrings &
        ResidueTypeStrings &
        ResidueCategoryStrings &
        CouponCategoryStrings &
        ResidueStrings &
        AdministratorStrings &
        SellerStrings = new LocalizedStrings({
        es: {
            ...globalStrings,
            ...loginStrings,
            ...passwordRecoveryStrings,
            ...dataTableStrings,
            ...residueStrings,
            ...residueTypeStrings,
            ...residueCategoryStrings,
            ...couponCategoryStrings,
            ...administratorStrings,
            ...sellerStrings,
        },
    });
}
