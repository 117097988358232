import { IModel } from '../../../../core/domain/interfaces/model_interface';

export type ResidueTypeModelArgs = {
    id: number;
    name: string;
    amount: number;
    kg: number;
    water: number;
    energy: number;
    co2: number;
    km: number;
    image: File | string;
    description: string;
};
export class ResidueTypeModel implements IModel {
    _type = 'ResidueTypeModel';
    id: number;
    name: string;
    amount: number;
    kg: number;
    water: number;
    energy: number;
    co2: number;
    km: number;
    image: File | string;
    description: string;

    constructor({ id, name, amount, kg, water, energy, co2, km, image, description }: ResidueTypeModelArgs) {
        this.id = id;
        this.name = name;
        this.amount = amount;
        this.kg = kg;
        this.water = water;
        this.energy = energy;
        this.co2 = co2;
        this.km = km;
        this.image = image;
        this.description = description;
    }
}
