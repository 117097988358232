import { AxiosInstance } from 'axios';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { ResidueCategoryEntity } from '../entities/residue_category_entity';
import { ICreateResidueCategoryValidations } from '../validations/create_residue_category_validations';
import { IUpdateResidueCategoryValidations } from '../validations/update_residue_category_validations';
import { IResidueCategoryDataSource } from './interfaces/residue_category_datasource_interface';

export class AxiosResidueCategoryDataSource implements IAxiosDataSource, IResidueCategoryDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async createResidueCategory(residueCategory: ResidueCategoryEntity): Promise<ResidueCategoryEntity> {
        const { name } = residueCategory;
        try {
            const { data } = await this.axiosInstance.post('residuecategories', {
                name,
            });
            return ResidueCategoryEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<ICreateResidueCategoryValidations>(error));
        }
    }
    async updateResidueCategory(residueCategory: ResidueCategoryEntity): Promise<ResidueCategoryEntity> {
        try {
            const { id, name } = residueCategory;
            const { data } = await this.axiosInstance.post(`residuecategories/${id}`, {
                name: name,
                _method: 'PUT',
            });
            return ResidueCategoryEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<IUpdateResidueCategoryValidations>(error));
        }
    }
    async deleteResidueCategory(residueCategory: ResidueCategoryEntity): Promise<void> {
        const { id } = residueCategory;
        try {
            return await this.axiosInstance.delete(`residuecategories/${id}`);
        } catch (error) {
            return Promise.reject(httpToError<ICreateResidueCategoryValidations>(error));
        }
    }
    async getResidueCategories(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueCategoryEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`residuecategories`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => ResidueCategoryEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
}
