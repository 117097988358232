import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { SellerModel } from '../models/seller_model';
import { ISellerRepository as ISellerRepository } from '../repositories/seller_repository_interface';

export class CreateSeller implements IUseCase<SellerModel, SellerModel> {
    sellerRepository: ISellerRepository;

    constructor(sellerRepository: ISellerRepository) {
        this.sellerRepository = sellerRepository;
    }

    execute(seller: SellerModel): Promise<SellerModel | IFailure> {
        return this.sellerRepository.createSeller(seller);
    }
}
