import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { ModalBloc } from '../../../../core/presentation/bloc/modal_bloc/modal_bloc';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import { ResidueCategoryModel } from '../../../residue_categories/domain/models/residue_category_model';
import { ResidueTypeModel } from '../../../residue_types/domain/models/residue_type_model';
import { ICreateResidueValidations } from '../../data/validations/create_residue_validations';
import { IUpdateResidueValidations } from '../../data/validations/update_residue_validations';
import { ResidueModel } from '../../domain/models/residue_model';

export type ResidueFormInputs = {
    residue: {
        id: number;
        name: string;
        barcode: string;
        image: string | File | undefined;
        residueType: ResidueTypeModel | undefined;
        residueCategory: ResidueCategoryModel | undefined;
    };
    residueCategories: ResidueCategoryModel[];
    residueTypes: ResidueTypeModel[];
};

export const emptyInputs: ResidueFormInputs = {
    residue: {
        id: -1,
        name: '',
        barcode: '',
        image: undefined,
        residueType: undefined,
        residueCategory: undefined,
    },
    residueCategories: [],
    residueTypes: [],
};
export type ResidueFormState = FormState<ResidueFormInputs, ResidueModel>;
export class ResidueFormBloc extends ModalBloc<ResidueFormState> {
    createResidueUseCase: IUseCase<ResidueModel, ResidueModel>;
    updateResidueUseCase: IUseCase<ResidueModel, ResidueModel>;
    getResidueTypesUseCase: IUseCase<DataTableResponse<ResidueTypeModel>, DataTableParams>;
    getResidueCategoriesUseCase: IUseCase<DataTableResponse<ResidueCategoryModel>, DataTableParams>;

    constructor(
        createResidueUseCase: IUseCase<ResidueModel, ResidueModel>,
        updateResidueUseCase: IUseCase<ResidueModel, ResidueModel>,
        getResidueTypesUseCase: IUseCase<DataTableResponse<ResidueTypeModel>, DataTableParams>,
        getResidueCategoriesUseCase: IUseCase<DataTableResponse<ResidueCategoryModel>, DataTableParams>,
    ) {
        super({
            open: false,
            block: false,
            _type: 'Idle',
            inputs: emptyInputs,
            action: 'read',
        });
        this.createResidueUseCase = createResidueUseCase;
        this.updateResidueUseCase = updateResidueUseCase;
        this.getResidueTypesUseCase = getResidueTypesUseCase;
        this.getResidueCategoriesUseCase = getResidueCategoriesUseCase;
    }

    private async getUseCase(action: string) {
        const {
            inputs: { residue },
        } = this.state;
        if (residue.image !== undefined && residue.residueType !== undefined && residue.residueCategory !== undefined) {
            const residueModel = new ResidueModel({
                ...residue,
                residueType: residue.residueType as ResidueTypeModel,
                residueCategory: residue.residueCategory as ResidueCategoryModel,
            });
            if (action === 'create') return await this.createResidueUseCase.execute(residueModel);
            if (action === 'update') return await this.updateResidueUseCase.execute(residueModel);
        } else {
            if (!residue.residueType)
                return new ValidationFailure<IUpdateResidueValidations | ICreateResidueValidations>({
                    residueType: {
                        code: 'required_error',
                        message: Language.strings.residue_residueType_required_error,
                    },
                });

            if (!residue.residueCategory)
                return new ValidationFailure<IUpdateResidueValidations | ICreateResidueValidations>({
                    residueCategory: {
                        code: 'required_error',
                        message: Language.strings.residue_residueCategory_required_error,
                    },
                });

            if (!residue.image)
                return new ValidationFailure<IUpdateResidueValidations | ICreateResidueValidations>({
                    image: { code: 'required_error', message: Language.strings.residue_image_required_error },
                });
        }
    }

    async submit(): Promise<void> {
        const { open, inputs, action } = this.state;
        if (action === 'read') return;
        this.changeState({ action, inputs, open, _type: 'Submitting', block: true });
        const result = await this.getUseCase(action);
        if (result instanceof Failure) {
            this.changeState({ action, inputs, open, block: false, _type: 'Failure', failure: result });
            return;
        }
        if (result instanceof ResidueModel)
            this.changeState({ action, inputs, open, block: false, _type: 'Success', data: result as ResidueModel });
    }

    async open(): Promise<void> {
        this.openFor('create');
    }
    async fillSelectors() {
        const residueTypesResponse = await this.getResidueTypesUseCase.execute({
            filters: [],
            order: undefined,
            page: 0,
            size: 0,
        });
        const residueCategoriesResponse = await this.getResidueCategoriesUseCase.execute({
            filters: [],
            order: undefined,
            page: 0,
            size: 0,
        });
        const residueTypes: ResidueTypeModel[] =
            residueTypesResponse instanceof Failure
                ? []
                : (residueTypesResponse as DataTableResponse<ResidueTypeModel>).data;
        const residueCategories: ResidueCategoryModel[] =
            residueCategoriesResponse instanceof Failure
                ? []
                : (residueCategoriesResponse as DataTableResponse<ResidueCategoryModel>).data;
        this.changeState({ ...this.state, inputs: { ...this.state.inputs, residueTypes, residueCategories } });
    }

    async openFor(action: 'read' | 'update' | 'create', data?: ResidueModel) {
        const { inputs } = this.state;
        if ((action === 'read' || action === 'update') && data === undefined) return;

        if ((action === 'read' || action === 'update') && data) {
            const { id, name, barcode, image, residueType, residueCategory } = data;
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: {
                    ...inputs,
                    residue: { id, name, barcode, image, residueType, residueCategory },
                },
                action,
            });
        }
        if (action === 'create')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: emptyInputs,
                action,
            });
        if (action !== 'read') {
            this.fillSelectors();
        }
    }
    async changeInput(
        input: 'name' | 'barcode' | 'image' | 'residueType' | 'residueCategory',
        value: string | File | ResidueCategoryModel | ResidueTypeModel | undefined,
    ) {
        this.changeState({
            ...this.state,
            _type: 'InputChanged',
            inputs: { ...this.state.inputs, residue: { ...this.state.inputs.residue, [input]: value } },
        });
    }
}
