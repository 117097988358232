import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueTypeModel } from '../models/residue_type_model';
import { IResidueTypeRepository } from '../repositories/residue_type_repository_interface';

export class GetResidueTypes implements IUseCase<DataTableResponse<ResidueTypeModel>, DataTableParams> {
    residueTypeRepository: IResidueTypeRepository;

    constructor(residueTypeRepository: IResidueTypeRepository) {
        this.residueTypeRepository = residueTypeRepository;
    }

    async execute(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueTypeModel> | IFailure> {
        const result = await this.residueTypeRepository.getResidueTypes(datatableParams);
        return Promise.resolve(result as IFailure);
    }
}
