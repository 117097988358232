import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    sellerDataTableBlocContext,
    sellerDeletionContext,
    sellerFormBlocContext,
} from '../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../core/presentation/utils/get_error_string';
import { SellerDataTable } from '../components/seller_datatable/seller_datatable';
import { SellerForm } from '../components/seller_form/seller_form';

export const SellersPage = () => {
    const sellerDataTableBloc = di.sellerDataTableBloc();
    const sellerFormBloc = di.sellerFormBloc();
    const sellerToggleBloc = di.sellerToggleBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (sellerDataTableBloc.state._type === 'Loaded') {
            sellerToggleBloc.askConfirmation(sellerDataTableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        sellerToggleBloc.delete();
        sellerDataTableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        sellerToggleBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (sellerDataTableBloc.state._type === 'Loaded') {
            sellerFormBloc.openFor('read', sellerDataTableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.toggleActiveUser)
                enqueueSnackbar(getErrorString('toggleActiveUser', { _type: 'Failure', failure }, 'seller'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'seller'), {
                    variant: 'error',
                });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
            return;
        }
        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.sellerStatusChanged, { variant: 'success' });
    };

    const handleCreate = () => {
        sellerFormBloc.openFor('create');
    };

    return (
        <>
            <sellerFormBlocContext.Provider value={sellerFormBloc}>
                <sellerDeletionContext.Provider value={sellerToggleBloc}>
                    <sellerDataTableBlocContext.Provider value={sellerDataTableBloc}>
                        <SellerDataTable onRead={handleRead} onDelete={handleAskConfirmation} onCreate={handleCreate} />
                    </sellerDataTableBlocContext.Provider>
                    <DeletionConfirmationModal
                        bloc={sellerToggleBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </sellerDeletionContext.Provider>
                <SellerForm />
            </sellerFormBlocContext.Provider>
        </>
    );
};
