export interface ResidueTypeStrings {
    residueType: string;
    residueTypes: string;
    residueTypeCreate: string;
    residueTypeRead: string;
    residueTypeUpdate: string;
    residueTypeDelete: string;
    residueTypeName: string;
    residueTypeAmount: string;
    residueTypeCreateSuccessful: string;
    residueTypeEditSuccessful: string;
    residueTypeDescriptionGood: string;
    residueTypeDescriptionBad: string;
    residueTypeKg: string;
    residueTypeWater: string;
    residueTypeEnergy: string;
    residueTypeCo2: string;
    residueTypeKm: string;
    residueTypeImage: string;
    //Errors
    residueType_id_exists_error: string;
    residueType_name_required_error: string;
    residueType_description_required_error: string;
    residueType_amount_required_error: string;
    residueType_kg_required_error: string;
    residueType_water_required_error: string;
    residueType_energy_required_error: string;
    residueType_co2_required_error: string;
    residueType_km_required_error: string;
    residueType_amount_numeric_error: string;
    residueType_kg_numeric_error: string;
    residueType_water_numeric_error: string;
    residueType_energy_numeric_error: string;
    residueType_km_numeric_error: string;
    residueType_image_max_error: string;
    residueType_image_mimes_error: string;
    residueType_co2_numeric_error: string;
    residueType_createResidueType_exist_user_error: string;
    residueType_updateResidueType_exist_user_error: string;
    residueType_deleteResidueType_exist_user_error: string;
    residueType_createResidueType_user_active_error: string;
    residueType_updateResidueType_user_active_error: string;
    residueType_deleteResidueType_user_active_error: string;
}

export const residueTypeStrings: ResidueTypeStrings = {
    residueType: 'Tipo de Residuo',
    residueTypes: 'Tipos de Residuo',
    residueTypeCreate: 'Crear',
    residueTypeRead: 'Ver',
    residueTypeUpdate: 'Editar',
    residueTypeDelete: 'Eliminar',
    residueTypeName: 'Nombre',
    residueTypeAmount: 'Puntos',
    residueTypeCreateSuccessful: 'El Tipo de Residuo ha sido creado correctamente',
    residueTypeEditSuccessful: 'El Tipo de Residuo ha sido editado correctamente',
    residueTypeDescriptionGood: '¿Qué es?',
    residueTypeDescriptionBad: '¿Qué NO es?',
    residueTypeKg: 'Equiv. Peso (Kg)',
    residueTypeWater: 'Equiv. Agua (Lts)',
    residueTypeEnergy: 'Equiv. Energía (kW/h)',
    residueTypeCo2: 'Equiv. CO2 (Kg)',
    residueTypeKm: 'Equiv. Distancia (Km)',
    residueTypeImage: 'Subir/Actualizar Imagen (120x120px)',
    //Errors
    residueType_id_exists_error: 'Error: El Tipo de Residuo seleccionado no existe.',
    residueType_name_required_error: 'El campo Nombre es requerido.',
    residueType_description_required_error: 'El campo ¿Qué es?/¿Qué No es? es requerido.',
    residueType_amount_required_error: 'El campo Puntos es requerido.',
    residueType_kg_required_error: 'El campo Equiv. Kg es requerido.',
    residueType_water_required_error: 'El campo Equiv. Agua es requerido.',
    residueType_energy_required_error: 'El campo Equiv. Energía es requerido.',
    residueType_co2_required_error: 'El campo Equiv. CO2 es requerido.',
    residueType_km_required_error: 'El campo Equiv. Km es requerido.',
    residueType_amount_numeric_error: 'El campo Puntos debe ser numérico.',
    residueType_kg_numeric_error: 'El campo Equiv. Kg debe ser numérico.',
    residueType_water_numeric_error: 'El campo Equiv. Agua debe ser numérico.',
    residueType_energy_numeric_error: 'El campo Equiv. Energía debe ser numérico.',
    residueType_co2_numeric_error: 'El campo Equiv. CO2 debe ser numérico.',
    residueType_km_numeric_error: 'El campo Equiv. Km debe ser numérico.',
    residueType_createResidueType_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residueType_updateResidueType_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residueType_deleteResidueType_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residueType_createResidueType_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residueType_updateResidueType_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residueType_deleteResidueType_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residueType_image_max_error: 'Error: La imagen debe ser pesar menos de 5 MB',
    residueType_image_mimes_error: 'Error: La imagen debe ser png, jpg o jpeg',
};
