import { Button, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import _ from 'lodash';
import { AccountCancel, AccountCheck } from 'mdi-material-ui';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { DataTableStates } from '../../../../../core/presentation/bloc/datatable_bloc/datatable_bloc_states';
import { DeletionState } from '../../../../../core/presentation/bloc/deletion_bloc/deletion_state';
import { FormState } from '../../../../../core/presentation/bloc/form_states/form_states';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import {
    DataTable,
    DataTableColumn,
    DataTableSelection,
} from '../../../../../core/presentation/components/datatable/datatable';
import {
    useSellerDataTableBloc,
    useSellerDeletionBloc,
    useSellerFormBloc,
} from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import { SellerModel } from '../../../domain/models/seller_model';
import { Logout } from './../../../../authentication/presentation/components/logout/logout';
import { useStyles } from './styles';

export const columns: DataTableColumn[] = [
    { name: 'surname', label: Language.strings.sellerSurname, type: 'text', db: 'users.surname' },
    { name: 'name', label: Language.strings.sellerName, type: 'text', db: 'users.name' },
    { name: 'username', label: Language.strings.sellerUsername, type: 'text', db: 'users.username' },
    { name: 'businessName', label: Language.strings.sellerBusinessName, type: 'text', db: 'business_name' },
    { name: 'active', label: Language.strings.sellerStatus, type: 'bool', db: 'users.active' },
];

export type SellerDataTableProps = {
    onRead?: (selection: DataTableSelection) => void;
    onEdit?: (selection: DataTableSelection) => void;
    onDelete?: (selection: DataTableSelection) => void;
    onCreate?: () => void;
};

export const SellerDataTable: React.FC<SellerDataTableProps> = ({
    onRead,
    onEdit,
    onDelete,
    onCreate,
}: SellerDataTableProps) => {
    const datatableBloc = useSellerDataTableBloc();
    const sellerFormBloc = useSellerFormBloc();
    const sellerDeletionBloc = useSellerDeletionBloc();

    useEffect(() => {
        datatableBloc.getSellers();

        const refresh = (state: DataTableStates<SellerModel> | (ModalState & FormState<SellerModel, SellerModel>)) => {
            if (['Success', 'FiltersChanged', 'OrderChanged', 'PageChanged', 'SizeChanged'].includes(state._type))
                datatableBloc.getSellers();
        };
        const reset = (state: DeletionState<SellerModel>) => {
            if (['Success'].includes(state._type)) {
                datatableBloc.changePage(0);
                datatableBloc.getSellers();
            }
        };

        datatableBloc.subscribe(refresh);
        sellerFormBloc.subscribe(refresh);
        sellerDeletionBloc.subscribe(reset);
        return () => {
            datatableBloc.unsubscribe(refresh);
            sellerFormBloc.unsubscribe(refresh);
            sellerDeletionBloc.unsubscribe(reset);
        };
    }, []);

    const SelectionActions = (props: any) => {
        const classes = useStyles();
        return (
            <>
                <Tooltip title={Language.strings.sellerRead} aria-label={Language.strings.sellerRead}>
                    <IconButton color="secondary" onClick={onRead ? () => onRead(props) : undefined}>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                {!_.isEmpty(props.data) ? (
                    <Tooltip
                        title={
                            props.data[props.dataIndex].active
                                ? Language.strings.sellerDeactivate
                                : Language.strings.sellerActivate
                        }
                    >
                        <IconButton
                            className={props.data[props.dataIndex].active ? classes.delete : classes.activate}
                            onClick={onDelete ? () => onDelete(props) : undefined}
                        >
                            {props.data[props.dataIndex].active ? <AccountCancel /> : <AccountCheck />}
                        </IconButton>
                    </Tooltip>
                ) : null}
            </>
        );
    };
    const ToolbarActions = (props: any) => {
        const classes = useStyles();
        return (
            <>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation={true}
                    startIcon={<AddIcon />}
                    onClick={onCreate ? () => onCreate() : undefined}
                >
                    {`${Language.strings.sellerCreate} ${Language.strings.seller}`}
                </Button>
            </>
        );
    };

    return (
        <BlocBuilder
            bloc={datatableBloc}
            builder={(state: DataTableStates<SellerModel>) => {
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <DataTable
                        columns={columns}
                        isLoading={state._type === 'Loading'}
                        data={state._type === 'Loaded' ? state.data.data : []}
                        total={state._type === 'Loaded' ? state.data.total : 0}
                        page={state.page}
                        selection={state.selection}
                        onRowSelectionChange={datatableBloc.changeSelection}
                        onColumnSortChange={datatableBloc.changeOrder}
                        onChangePage={datatableBloc.changePage}
                        onChangeRowsPerPage={datatableBloc.changeSize}
                        onFilterChange={datatableBloc.changeFilters}
                        selectionActions={<SelectionActions data={state._type === 'Loaded' ? state.data.data : []} />}
                        toolbarActions={<ToolbarActions />}
                    />
                );
            }}
        />
    );
};
