import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueTypeModel } from '../models/residue_type_model';
import { IResidueTypeRepository } from '../repositories/residue_type_repository_interface';

export class DeleteResidueType implements IUseCase<void, ResidueTypeModel> {
    residueTypeRepository: IResidueTypeRepository;

    constructor(residueTypeRepository: IResidueTypeRepository) {
        this.residueTypeRepository = residueTypeRepository;
    }

    async execute(residueType: ResidueTypeModel): Promise<void | IFailure> {
        return this.residueTypeRepository.deleteResidueType(residueType);
    }
}
