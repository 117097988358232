import AllInboxIcon from '@material-ui/icons/AllInbox';
import CategoryIcon from '@material-ui/icons/Category';
import { AccountTie, Handshake, Recycle, Ticket } from 'mdi-material-ui';
import React from 'react';
import { AdministratorsPage } from '../../../features/administrators/presentation/pages/administrators_page';
import { CouponCategoriesPage } from '../../../features/coupon_categories/presentation/pages/coupon_category_page';
import { ResiduesPage } from '../../../features/residues/presentation/pages/residues_page';
import { ResidueCategoriesPage } from '../../../features/residue_categories/presentation/pages/residue_category_page';
import { ResidueTypePage } from '../../../features/residue_types/presentation/pages/residue_types_page';
import { Language } from '../strings/LanguageManager';
import { SellersPage } from './../../../features/sellers/presentation/pages/sellers_page';

export const routes = [
    // {
    //     url: '/dashboard',
    //     title: 'Inicio',
    //     icon: <DashboardIcon />,
    //     component: <>Dashboard</>,
    // },
    {
        url: '/sellers',
        title: Language.strings.sellers,
        icon: <Handshake />,
        component: <SellersPage />,
        separator: false,
    },
    {
        url: '/administrators',
        title: Language.strings.administrators,
        icon: <AccountTie />,
        component: <AdministratorsPage />,
        separator: true,
    },
    {
        url: '/residue-types',
        title: Language.strings.residueTypes,
        icon: <AllInboxIcon />,
        component: <ResidueTypePage />,
        separator: false,
    },
    {
        url: '/residues',
        title: Language.strings.residues,
        icon: <Recycle />,
        component: <ResiduesPage />,
        separator: false,
    },
    {
        url: '/residue-categories',
        title: Language.strings.residueCategories,

        icon: <CategoryIcon />,
        component: <ResidueCategoriesPage />,
        separator: false,
    },
    {
        url: '/coupon-categories',
        title: Language.strings.couponCategories,
        component: <CouponCategoriesPage />,
        icon: <Ticket />,
        separator: true,
    },
];
