import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    residueCategoryDataTableBlocContext,
    residueCategoryDeletionContext,
    residueCategoryFormBlocContext,
} from '../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../core/presentation/utils/get_error_string';
import { ResidueCategoriesDataTable } from '../components/residue_category_datatable/residue_category_datatable';
import { ResidueCategoryForm } from '../components/residue_category_form/residue_category_form';

export const ResidueCategoriesPage = () => {
    const residueCategorysDatatableBloc = di.residueCategoryDataTableBloc();
    const residueCategoryFormBloc = di.residueCategoryFormBloc();
    const residueCategoryDeletionBloc = di.residueCategoryDeletionBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleEdit = ({ index, dataIndex }: DataTableSelection) => {
        if (residueCategorysDatatableBloc.state._type === 'Loaded') {
            residueCategoryFormBloc.openFor('update', residueCategorysDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (residueCategorysDatatableBloc.state._type === 'Loaded') {
            residueCategoryDeletionBloc.askConfirmation(residueCategorysDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        residueCategoryDeletionBloc.delete();
        residueCategorysDatatableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        residueCategoryDeletionBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (residueCategorysDatatableBloc.state._type === 'Loaded') {
            residueCategoryFormBloc.openFor('read', residueCategorysDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.deleteResidueCategory)
                enqueueSnackbar(
                    getErrorString('deleteResidueCategory', { _type: 'Failure', failure }, 'residueCategory'),
                    {
                        variant: 'error',
                    },
                );
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'residueCategory'), {
                    variant: 'error',
                });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.loggedOut, { variant: 'error' });
            return;
        }
        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.deletionSuccess, { variant: 'success' });
    };

    const handleCreate = () => {
        residueCategoryFormBloc.openFor('create');
    };

    return (
        <>
            <residueCategoryFormBlocContext.Provider value={residueCategoryFormBloc}>
                <residueCategoryDeletionContext.Provider value={residueCategoryDeletionBloc}>
                    <residueCategoryDataTableBlocContext.Provider value={residueCategorysDatatableBloc}>
                        <ResidueCategoriesDataTable
                            onRead={handleRead}
                            onEdit={handleEdit}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </residueCategoryDataTableBlocContext.Provider>
                    <DeletionConfirmationModal
                        bloc={residueCategoryDeletionBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </residueCategoryDeletionContext.Provider>
                <ResidueCategoryForm />
            </residueCategoryFormBlocContext.Provider>
        </>
    );
};
