import { createStyles, makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        active: {
            ['& .MuiButtonBase-root']: {
                backgroundColor: theme.colors.active,
                color: theme.palette.text.primary,
                ['& .MuiListItemIcon-root']: {
                    color: theme.palette.text.primary,
                },
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        logo: {
            [theme.breakpoints.down('xs')]: {
                position: 'absolute',
                margin: '0 auto',
                left: 0,
                right: 0,
            },
        },
        logout: {
            marginLeft: 'auto',
        },
        separator: {
            borderBottom: `1px solid ${theme.colors.grey}`,
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
        },
    }),
);
