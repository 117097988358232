import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { AdministratorEntity } from '../entites/administrator_entity';
import { ICreateAdministratorValidations } from '../validations/create_administrator_validations';
import { IToggleAdministratorValidations } from '../validations/toggle_administrator_validations';
import { IAdministratorDataSource } from './interfaces/administrator_datasource_interface';

export class AxiosAdministratorDataSource implements IAxiosDataSource, IAdministratorDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async createAdministrator(administrator: AdministratorEntity): Promise<AdministratorEntity> {
        const { surname, name, username, email, identificationNumber } = administrator;
        try {
            const { data } = await this.axiosInstance.post('administrators', {
                surname,
                name,
                username,
                email,
                identification_number: identificationNumber,
            });
            return AdministratorEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICreateAdministratorValidations>({
                        ...error.response.data,
                        businessName: error.response.data.business_name,
                        identificationNumber: error.response.data.identification_number,
                    }),
                );
            }
            return Promise.reject(httpToError<ICreateAdministratorValidations>(error));
        }
    }
    async toggleAdministrator(administrator: AdministratorEntity): Promise<void> {
        const { id } = administrator;
        try {
            return await this.axiosInstance.post(`users/${id}/status`, { _method: 'PUT' });
        } catch (error) {
            return Promise.reject(httpToError<IToggleAdministratorValidations>(error));
        }
    }
    async getAdministrators(datatableParams: DataTableParams): Promise<DataTableResponse<AdministratorEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`administrators`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => AdministratorEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
}
