import { Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import di from '../../../../../core/app/dependency_injection/di';
import logo_large from '../../../../../core/presentation/assets/logos/logo_large.svg';
import { loginPageContext } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import { LoginForm } from '../../components/login_form/login_form';
import { useStyles } from './styles';

export const LoginPage = () => {
    const classes = useStyles();
    const loginBloc = di.loginPageBloc();
    return (
        <div className={classes.container}>
            <img src={logo_large} className={classes.logo} />

            <Card className={classes.paper} elevation={5}>
                <CardHeader title={Language.strings.welcome} />
                <CardContent>
                    <loginPageContext.Provider value={loginBloc}>
                        <LoginForm />
                    </loginPageContext.Provider>
                </CardContent>
            </Card>
        </div>
    );
};
