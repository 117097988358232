import { Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useAuthBloc } from '../../contexts/contexts';
import { routes } from '../../routes/auth_routes';
import { Language } from '../../strings/LanguageManager';
import Logo from '../logo/logo';
import { useStyles } from './styles';

interface Props {
    children?: React.ReactElement;
}

export default function ResponsiveDrawer(props: Props) {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [active, setActive] = React.useState('');
    const history = useHistory();
    const authenticationBloc = useAuthBloc();
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const handleNavClicked = (url: string) => {
        setActive(url);
        history.push(url);
    };

    const handleLogout = () => {
        authenticationBloc.logout();
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            {routes.map((item) => (
                <NavLink
                    key={item.url}
                    to={item.url}
                    onClick={() => setMobileOpen(false)}
                    activeClassName={classes.active}
                    className={classes.link}
                >
                    <ListItem button className={(item.separator && classes.separator) || ''}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                </NavLink>
            ))}
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar} color="secondary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Logo size={8} variant="large" className={classes.logo} />
                    <Tooltip title={Language.strings.logout}>
                        <IconButton
                            color="inherit"
                            aria-label="logout"
                            edge="end"
                            onClick={handleLogout}
                            className={classes.logout}
                        >
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
}
