import { Failure } from '../../../../core/domain/failures/failure';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { FormState } from '../../../../core/presentation/bloc/form_states/form_states';
import { ModalBloc } from '../../../../core/presentation/bloc/modal_bloc/modal_bloc';
import { SellerModel } from '../../domain/models/seller_model';

export const emptyInputs: SellerModel = new SellerModel({
    id: -1,
    username: '',
    name: '',
    surname: '',
    identificationNumber: 123,
    email: '',
    businessName: '',
    active: false,
});
export type SellerFormState = FormState<SellerModel, SellerModel>;
export class SellerFormBloc extends ModalBloc<SellerFormState> {
    createSellerUseCase: IUseCase<SellerModel, SellerModel>;

    constructor(createSellerUseCase: IUseCase<SellerModel, SellerModel>) {
        super({
            open: false,
            block: false,
            _type: 'Idle',
            inputs: emptyInputs,
            action: 'read',
        });
        this.createSellerUseCase = createSellerUseCase;
    }

    async submit(): Promise<void> {
        const { open, inputs, action } = this.state;
        if (action === 'read') return;
        this.changeState({ action, inputs, open, _type: 'Submitting', block: true });
        const result = await this.createSellerUseCase.execute(this.state.inputs);
        if (result instanceof Failure) {
            this.changeState({ action, inputs, open, block: false, _type: 'Failure', failure: result });
            return;
        }

        this.changeState({
            action,
            inputs,
            open,
            block: false,
            _type: 'Success',
            data: result as SellerModel,
        });
    }

    async open(): Promise<void> {
        this.openFor('create');
    }

    async openFor(action: 'read' | 'create', data?: SellerModel) {
        if (action === 'read' && data !== undefined)
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: data,
                action,
            });
        if (action === 'create')
            this.changeState({
                _type: 'Loaded',
                open: true,
                block: false,
                inputs: emptyInputs,
                action,
            });
    }
    async changeInput(
        input: 'name' | 'surname' | 'username' | 'identificationNumber' | 'email' | 'businessName',
        value: number | string,
    ) {
        this.changeState({
            ...this.state,
            _type: 'InputChanged',
            inputs: { ...this.state.inputs, [input]: value },
        });
    }
}
