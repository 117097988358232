import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueCategoryModel } from '../models/residue_category_model';
import { IResidueCategoryRepository } from '../repositories/residue_category_repository_interface';

export class UpdateResidueCategory implements IUseCase<ResidueCategoryModel, ResidueCategoryModel> {
    residueCategoryRepository: IResidueCategoryRepository;

    constructor(residueCategoryRepository: IResidueCategoryRepository) {
        this.residueCategoryRepository = residueCategoryRepository;
    }

    execute(residueCategory: ResidueCategoryModel): Promise<ResidueCategoryModel | IFailure> {
        return this.residueCategoryRepository.updateResidueCategory(residueCategory);
    }
}
