import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { AdministratorModel } from '../models/administrator_model';
import { IAdministratorRepository as IAdministratorRepository } from '../repositories/administrator_repository_interface';

export class CreateAdministrator implements IUseCase<AdministratorModel, AdministratorModel> {
    administratorRepository: IAdministratorRepository;

    constructor(administratorRepository: IAdministratorRepository) {
        this.administratorRepository = administratorRepository;
    }

    execute(administrator: AdministratorModel): Promise<AdministratorModel | IFailure> {
        return this.administratorRepository.createAdministrator(administrator);
    }
}
