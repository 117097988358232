import { areAnyNil } from '../../../../core/app/utils/utils';
import { ConvertionError } from '../../../../core/data/errors/convertion_error';
import { IEntity } from '../../../../core/data/interfaces/entity_interface';
import { ResidueCategoryEntity } from '../../../residue_categories/data/entities/residue_category_entity';
import { ResidueCategoryModel } from '../../../residue_categories/domain/models/residue_category_model';
import { ResidueTypeEntity } from '../../../residue_types/data/entities/residue_type_entity';
import { ResidueTypeModel } from '../../../residue_types/domain/models/residue_type_model';
import { ResidueModel } from '../../domain/models/residue_model';

export type ResidueEntityArgs = {
    id: number;
    name: string;
    barcode: string;
    image?: string | undefined | File;
    residueType: ResidueTypeEntity;
    residueCategory: ResidueCategoryEntity;
};

export class ResidueEntity implements IEntity {
    _type = 'ResidueEntity';
    id: number;
    name: string;
    barcode: string;
    image?: string | undefined | File;
    residueType: ResidueTypeEntity;
    residueCategory: ResidueCategoryEntity;

    constructor({ id, name, image, barcode, residueType, residueCategory }: ResidueEntityArgs) {
        this.id = id;
        this.name = name;
        this.image = image;
        this.barcode = barcode;
        this.residueType = residueType;
        this.residueCategory = residueCategory;
    }

    toModel(): ResidueModel {
        return new ResidueModel({
            ...this,
            residueCategory: new ResidueCategoryModel(this.residueCategory),
            residueType: new ResidueTypeModel(this.residueType),
        });
    }

    static fromObject(obj: any): ResidueEntity {
        try {
            const { id, name, image, barcode, residue_type, residue_category } = obj;
            if (areAnyNil([id, name, residue_type, residue_category])) {
                throw new ConvertionError(JSON.stringify(obj), 'ResidueEntity');
            }
            return new ResidueEntity({
                id,
                name,
                image,
                barcode,
                residueType: ResidueTypeEntity.fromObject(residue_type),
                residueCategory: ResidueCategoryEntity.fromObject(residue_category),
            });
        } catch (error) {
            throw new ConvertionError(JSON.stringify(obj), 'ResidueEntity');
        }
    }
}
