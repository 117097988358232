import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    couponCategoryDataTableBlocContext,
    couponCategoryDeletionContext,
    couponCategoryFormBlocContext,
} from '../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../core/presentation/utils/get_error_string';
import { CouponCategoriesDataTable } from '../components/coupon_category_datatable/coupon_category_datatable';
import { CouponCategoryForm } from '../components/coupon_category_form/coupon_category_form';

export const CouponCategoriesPage = () => {
    const couponCategorysDatatableBloc = di.couponCategoryDataTableBloc();
    const couponCategoryFormBloc = di.couponCategoryFormBloc();
    const couponCategoryDeletionBloc = di.couponCategoryDeletionBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleEdit = ({ index, dataIndex }: DataTableSelection) => {
        if (couponCategorysDatatableBloc.state._type === 'Loaded') {
            couponCategoryFormBloc.openFor('update', couponCategorysDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (couponCategorysDatatableBloc.state._type === 'Loaded') {
            couponCategoryDeletionBloc.askConfirmation(couponCategorysDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        couponCategoryDeletionBloc.delete();
        couponCategorysDatatableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        couponCategoryDeletionBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (couponCategorysDatatableBloc.state._type === 'Loaded') {
            couponCategoryFormBloc.openFor('read', couponCategorysDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.deleteCategory)
                enqueueSnackbar(getErrorString('deleteCategory', { _type: 'Failure', failure }, 'couponCategory'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'couponCategory'), {
                    variant: 'error',
                });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.loggedOut, { variant: 'error' });
            return;
        }
        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.deletionSuccess, { variant: 'success' });
    };

    const handleCreate = () => {
        couponCategoryFormBloc.openFor('create');
    };

    return (
        <>
            <couponCategoryFormBlocContext.Provider value={couponCategoryFormBloc}>
                <couponCategoryDeletionContext.Provider value={couponCategoryDeletionBloc}>
                    <couponCategoryDataTableBlocContext.Provider value={couponCategorysDatatableBloc}>
                        <CouponCategoriesDataTable
                            onRead={handleRead}
                            onEdit={handleEdit}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </couponCategoryDataTableBlocContext.Provider>
                    <DeletionConfirmationModal
                        bloc={couponCategoryDeletionBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </couponCategoryDeletionContext.Provider>
                <CouponCategoryForm />
            </couponCategoryFormBlocContext.Provider>
        </>
    );
};
