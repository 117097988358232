import { createMuiTheme } from '@material-ui/core/styles';

export default function createLightTheme() {
    return createMuiTheme(
        {
            palette: {
                background: { default: '#edf1f0' },
                primary: { main: '#FFB901' },
                secondary: { main: '#1b5853', contrastText: '#FFFFFF' },
                text: { primary: '#1b5853' },
            },
            typography: {
                h1: { fontStyle: 'normal', fontWeight: 900, fontSize: '32px', lineHeight: '100%' },
                h2: { fontSize: '1.25rem', fontStyle: 'normal', fontWeight: 'normal' },
                button: { textTransform: 'none' },
            },
            colors: {
                main: '#CCD5D2C4',
                whiteTransparent: '#FFFFFFC4',
                white: '#FFFFFF',
                grey: '#EAEAEA',
                greyTransparent: '#EAEAEAC4',
                active: '#F2C6A1',
                danger: '#fd6767',
                success: '#48bf93',
            },
            shape: { borderRadius: 10 },
            button: { secondary: { main: '#B36616', dark: '#915313', contrastText: '#FFFFFF' } },
            input: {
                radius: '10px',
                borderColor: { main: '#1b5853', active: '#FFB901' },
            },
            overrides: {
                MuiCssBaseline: {
                    '@global': {
                        html: {
                            WebkitFontSmoothing: 'auto',
                        },
                        ['.link-dark']: {
                            color: '#1b5853',
                        },
                    },
                },
            },
        },
        {
            overrides: {
                MUIDataTableFilter: {
                    root: {
                        ['& .MuiGrid-item']: {
                            marginTop: 0,
                        },
                    },
                },
                MUIDataTableToolbar: {
                    filterPaper: {
                        maxWidth: '500px',
                        width: '100%',
                        marginLeft: '16px',
                    },
                },
            },
        },
    );
}
declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
        colors: {
            main: string;
            whiteTransparent: string;
            greyTransparent: string;
            white: string;
            grey: string;
            active: string;
            danger: string;
            success: string;
        };
        button: { secondary: { main: string; dark: string; contrastText: string } };
        input: { radius: string; borderColor: { main: string; active: string } };
    }
    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        colors?: {
            main?: string;
            whiteTransparent?: string;
            greyTransparent?: string;
            white?: string;
            grey?: string;
            active?: string;
            danger?: string;
            success?: string;
        };
        button?: {
            secondary?: {
                main?: string;
                dark: string;
                contrastText?: string;
            };
        };
        input?: { radius: string; borderColor: { main: string; active: string } };
    }
}
