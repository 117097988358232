import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { ResidueCategoryEntity } from '../../../residue_categories/data/entities/residue_category_entity';
import { ResidueTypeEntity } from '../../../residue_types/data/entities/residue_type_entity';
import { ResidueModel } from '../../domain/models/residue_model';
import { IResidueRepository } from '../../domain/repositories/residue_repository';
import { IResidueDataSource } from '../datasources/interfaces/residue_datasource_interface';
import { ResidueEntity } from '../entities/residue_entity';
import { ICreateResidueValidations } from '../validations/create_residue_validations';
import { IDeleteResidueValidations } from '../validations/delete_residue_validations';
import { IUpdateResidueValidations } from '../validations/update_residue_validations';

export class ResidueRepository implements IResidueRepository {
    residueDataSource: IResidueDataSource;

    constructor(residueDataSource: IResidueDataSource) {
        this.residueDataSource = residueDataSource;
    }
    async createResidue(residue: ResidueModel): Promise<ResidueModel | IFailure> {
        try {
            const entity: ResidueEntity = await this.residueDataSource.createResidue(
                new ResidueEntity({
                    ...residue,
                    residueCategory: new ResidueCategoryEntity(residue.residueCategory),
                    residueType: new ResidueTypeEntity(residue.residueType),
                }),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<ICreateResidueValidations>(error));
        }
    }
    async updateResidue(residue: ResidueModel): Promise<ResidueModel | IFailure> {
        try {
            const entity: ResidueEntity = await this.residueDataSource.updateResidue(
                new ResidueEntity({
                    ...residue,
                    residueCategory: new ResidueCategoryEntity(residue.residueCategory),
                    residueType: new ResidueTypeEntity(residue.residueType),
                }),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<IUpdateResidueValidations>(error));
        }
    }
    async deleteResidue(residue: ResidueModel): Promise<void | IFailure> {
        try {
            return await this.residueDataSource.deleteResidue(
                new ResidueEntity({
                    ...residue,
                    residueCategory: new ResidueCategoryEntity(residue.residueCategory),
                    residueType: new ResidueTypeEntity(residue.residueType),
                }),
            );
        } catch (error) {
            return Promise.resolve(errorToFailure<IDeleteResidueValidations>(error));
        }
    }
    async getResidues(datatableParams: DataTableParams): Promise<IFailure | DataTableResponse<ResidueModel>> {
        try {
            const result: DataTableResponse<ResidueEntity> = await this.residueDataSource.getResidues(datatableParams);
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure<IDataTableValidations>(error));
        }
    }
}
