import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueCategoryModel } from '../models/residue_category_model';
import { IResidueCategoryRepository } from '../repositories/residue_category_repository_interface';

export class GetResidueCategories implements IUseCase<DataTableResponse<ResidueCategoryModel>, DataTableParams> {
    residueCategoryRepository: IResidueCategoryRepository;

    constructor(residueCategoryRepository: IResidueCategoryRepository) {
        this.residueCategoryRepository = residueCategoryRepository;
    }

    execute(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueCategoryModel> | IFailure> {
        return this.residueCategoryRepository.getResidueCategories(datatableParams);
    }
}
