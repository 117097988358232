import { IModel } from '../../../../core/domain/interfaces/model_interface';

export type CouponCategoryModelArgs = {
    id: number;
    name: string;
};
export class CouponCategoryModel implements IModel {
    _type = 'CouponCategoryModel';
    id: number;
    name: string;

    constructor({ id, name }: CouponCategoryModelArgs) {
        this.id = id;
        this.name = name;
    }
}
