export interface SellerStrings {
    seller: string;
    sellers: string;
    sellerCreate: string;
    sellerRead: string;
    sellerActivate: string;
    sellerDeactivate: string;
    sellerName: string;
    sellerSurname: string;
    sellerUsername: string;
    sellerIdentificationNumber: string;
    sellerBusinessName: string;
    sellerEmail: string;
    sellerCreateSuccessful: string;
    sellerStatus: string;
    sellerStatusChanged: string;

    //Errors
    seller_id_exists_error: string;
    seller_name_required_error: string;
    seller_surname_required_error: string;
    seller_username_required_error: string;
    seller_username_unique_error: string;
    seller_email_required_error: string;
    seller_email_unique_error: string;
    seller_email_email_error: string;
    seller_identificationNumber_required_error: string;
    seller_identificationNumber_numeric_error: string;
    seller_identificationNumber_min_error: string;
    seller_businessName_required_error: string;

    seller_toggleActiveUser_exist_user_error: string;
    seller_toggleActiveUser_user_active_error: string;
    seller_createUserSeller_exist_user_error: string;
    seller_createUserSeller_user_active_error: string;
    seller_id_notin_error: string;
}

export const sellerStrings: SellerStrings = {
    seller: 'Cliente',
    sellers: 'Clientes',
    sellerCreate: 'Crear',
    sellerRead: 'Ver',
    sellerActivate: 'Activar',
    sellerDeactivate: 'Desactivar',
    sellerName: 'Nombre',
    sellerSurname: 'Apellido',
    sellerUsername: 'Usuario',
    sellerIdentificationNumber: 'DNI | CUIL | CUIT',
    sellerBusinessName: 'Razón Social',
    sellerEmail: 'Email',
    sellerCreateSuccessful: 'El Cliente ha sido creado correctamente',
    sellerStatus: 'Estado',
    sellerStatusChanged: 'El estado ha sido cambiado exitosamente',

    seller_id_exists_error: 'Error: El usuario seleccionado no existe.',
    seller_name_required_error: 'El campo Nombre es requerido.',
    seller_surname_required_error: 'El campo Apellido es requerido.',
    seller_email_required_error: 'El campo Email es requerido.',
    seller_email_unique_error: 'El Email ya ha sido usado.',
    seller_email_email_error: 'El campo Email es inválido',
    seller_identificationNumber_required_error: 'El campo DNI | CUIL | CUIT es requerido.',
    seller_identificationNumber_numeric_error: 'El campo DNI | CUIL | CUIT debe ser un número.',
    seller_identificationNumber_min_error: 'El campo DNI | CUIL | CUIT debe tener al menos 8 números',
    seller_businessName_required_error: 'El campo Razón Social es requerido.',
    seller_toggleActiveUser_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    seller_toggleActiveUser_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    seller_id_notin_error: 'Error: No puede habilitar | deshabilitar su propia cuenta.',
    seller_createUserSeller_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    seller_createUserSeller_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    seller_username_required_error: 'El campo Usuario es requerido',
    seller_username_unique_error: 'El Usuario ya esta siendo usado.',
};
