import { Button, Grid, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { useResidueCategoryFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { Logout } from '../../../../authentication/presentation/components/logout/logout';
import { ResidueCategoryFormBloc, ResidueCategoryFormState } from '../../blocs/residue_category_form_bloc';
import { useStyles } from './styles';

export const ResidueCategoryForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: ResidueCategoryFormBloc = useResidueCategoryFormBloc();
    const classes = useStyles();

    useEffect(() => {
        const showSnackBar = (state: ModalState & ResidueCategoryFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'residueCategory'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.createResidueCategory)
                            enqueueSnackbar(getErrorString('createResidueCategory', state, 'residueCategory'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.updateResidueCategory)
                            enqueueSnackbar(getErrorString('updateResidueCategory', state, 'residueCategory'), {
                                variant: 'error',
                            });
                        return;
                    }
                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }
                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.residueCategoryCreateSuccessful, { variant: 'success' });
                if (state._type === 'Success' && state.action === 'update')
                    enqueueSnackbar(Language.strings.residueCategoryEditSuccessful, { variant: 'success' });
            }
        };
        bloc.subscribe(showSnackBar);

        return () => {
            bloc.unsubscribe(showSnackBar);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeInput(event.target.name as 'name', event.target.value);
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ModalState & ResidueCategoryFormState) => {
                const { action, inputs } = state;
                const title =
                    (action && action === 'read' && Language.strings.residueCategoryRead) ||
                    (action === 'create' && Language.strings.residueCategoryCreate) ||
                    (action === 'update' && Language.strings.residueCategoryUpdate) ||
                    '';
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <FullScreenDialog
                        title={`${title} ${Language.strings.residueResidueCategory}`}
                        open={state.open}
                        disableClose={state.block}
                        actions={
                            state.action !== 'read' ? (
                                <Button
                                    color="inherit"
                                    onClick={handleSubmit}
                                    disabled={state._type === 'Submitting'}
                                    startIcon={<SaveIcon />}
                                >
                                    {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                </Button>
                            ) : undefined
                        }
                        content={
                            <Grid container item xs={12} direction="column" className={`${classes.container}`}>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.residueCategoryName}
                                        name="name"
                                        value={inputs.name}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.name
                                        }
                                        helperText={getErrorString('name', state, 'residueCategory')}
                                        autoFocus
                                    />
                                </Grid>
                            </Grid>
                        }
                        onClose={() => bloc.close()}
                    />
                );
            }}
        />
    );
};
