import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { ResidueCategoryModel } from '../../domain/models/residue_category_model';
import { IResidueCategoryRepository } from '../../domain/repositories/residue_category_repository_interface';
import { IResidueCategoryDataSource } from '../datasources/interfaces/residue_category_datasource_interface';
import { ResidueCategoryEntity } from '../entities/residue_category_entity';
import { ICreateResidueCategoryValidations } from '../validations/create_residue_category_validations';
import { IDeleteResidueCategoryValidations } from '../validations/delete_residue_category_validations';
import { IUpdateResidueCategoryValidations } from '../validations/update_residue_category_validations';

export class ResidueCategoryRepository implements IResidueCategoryRepository {
    datasource: IResidueCategoryDataSource;

    constructor(datasource: IResidueCategoryDataSource) {
        this.datasource = datasource;
    }

    async createResidueCategory(residueCategory: ResidueCategoryModel): Promise<ResidueCategoryModel | IFailure> {
        try {
            const entity: ResidueCategoryEntity = await this.datasource.createResidueCategory(
                new ResidueCategoryEntity(residueCategory),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<ICreateResidueCategoryValidations>(error));
        }
    }
    async updateResidueCategory(residueCategory: ResidueCategoryModel): Promise<ResidueCategoryModel | IFailure> {
        try {
            const entity: ResidueCategoryEntity = await this.datasource.updateResidueCategory(
                new ResidueCategoryEntity(residueCategory),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<IUpdateResidueCategoryValidations>(error));
        }
    }
    async deleteResidueCategory(residueCategory: ResidueCategoryModel): Promise<void | IFailure> {
        try {
            return await this.datasource.deleteResidueCategory(new ResidueCategoryEntity(residueCategory));
        } catch (error) {
            return Promise.resolve(errorToFailure<IDeleteResidueCategoryValidations>(error));
        }
    }
    async getResidueCategories(
        datatableParams: DataTableParams,
    ): Promise<IFailure | DataTableResponse<ResidueCategoryModel>> {
        try {
            const result: DataTableResponse<ResidueCategoryEntity> = await this.datasource.getResidueCategories(
                datatableParams,
            );
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure<IDataTableValidations>(error));
        }
    }
}
