import { AdministratorDataTableBloc } from '../../../features/administrators/presentation/blocs/administrator_datatable_bloc';
import { AdministratorFormBloc } from '../../../features/administrators/presentation/blocs/administrator_form_bloc';
import { AuthenticationBloc } from '../../../features/authentication/presentation/blocs/authentication_bloc/authentication_bloc';
import { LoginPageBloc } from '../../../features/authentication/presentation/blocs/login_page_bloc/login_page_bloc';
import { PasswordRecoveryBloc } from '../../../features/authentication/presentation/blocs/password_recovery_bloc/password_recovery_bloc';
import { CouponCategoryModel } from '../../../features/coupon_categories/domain/models/coupon_category_model';
import { CouponCategoryDataTableBloc } from '../../../features/coupon_categories/presentation/blocs/coupon_category_datatable_bloc';
import { CouponCategoryFormBloc } from '../../../features/coupon_categories/presentation/blocs/coupon_category_form_bloc';
import { ResidueModel } from '../../../features/residues/domain/models/residue_model';
import { ResiduesDataTableBloc } from '../../../features/residues/presentation/blocs/residues_datatable_bloc';
import { ResidueFormBloc } from '../../../features/residues/presentation/blocs/residue_form_bloc';
import { ResidueCategoryModel } from '../../../features/residue_categories/domain/models/residue_category_model';
import { ResidueCategoryDataTableBloc } from '../../../features/residue_categories/presentation/blocs/residue_category_datatable_bloc';
import { ResidueCategoryFormBloc } from '../../../features/residue_categories/presentation/blocs/residue_category_form_bloc';
import { ResidueTypeModel } from '../../../features/residue_types/domain/models/residue_type_model';
import { ResidueTypesDataTableBloc } from '../../../features/residue_types/presentation/blocs/residue_type_datatable_bloc';
import { ResidueTypeFormBloc } from '../../../features/residue_types/presentation/blocs/residue_type_form_bloc';
import { SellerModel } from '../../../features/sellers/domain/models/seller_model';
import { SellerDataTableBloc } from '../../../features/sellers/presentation/blocs/seller_datatable_bloc';
import { SellerFormBloc } from '../../../features/sellers/presentation/blocs/seller_form_bloc';
import { createContext } from '../bloc/Context';
import { DeletionBloc } from '../bloc/deletion_bloc/deletion_bloc';
import { AdministratorModel } from './../../../features/administrators/domain/models/administrator_model';

export const [loginPageContext, useLoginPageBloc] = createContext<LoginPageBloc>();

export const [authContext, useAuthBloc] = createContext<AuthenticationBloc>();

export const [passwordRecoveryContext, usePasswordRecoveryBloc] = createContext<PasswordRecoveryBloc>();

export const [residueTypeFormBlocContext, useResidueTypeFormBloc] = createContext<ResidueTypeFormBloc>();

export const [residueTypeDataTableBlocContext, useResidueTypeDataTableBloc] =
    createContext<ResidueTypesDataTableBloc>();

export const [residueTypeDeletionContext, useResidueTypeDeletionBloc] = createContext<DeletionBloc<ResidueTypeModel>>();

export const [residueCategoryFormBlocContext, useResidueCategoryFormBloc] = createContext<ResidueCategoryFormBloc>();

export const [residueCategoryDataTableBlocContext, useResidueCategoryDataTableBloc] =
    createContext<ResidueCategoryDataTableBloc>();

export const [residueCategoryDeletionContext, useResidueCategoryDeletionBloc] =
    createContext<DeletionBloc<ResidueCategoryModel>>();

export const [residueFormBlocContext, useResidueFormBloc] = createContext<ResidueFormBloc>();

export const [residuesDataTableBlocContext, useResiduesDataTableBloc] = createContext<ResiduesDataTableBloc>();

export const [residueDeletionContext, useResidueDeletionBloc] = createContext<DeletionBloc<ResidueModel>>();

export const [couponCategoryFormBlocContext, useCouponCategoryFormBloc] = createContext<CouponCategoryFormBloc>();

export const [couponCategoryDataTableBlocContext, useCouponCategoryDataTableBloc] =
    createContext<CouponCategoryDataTableBloc>();

export const [couponCategoryDeletionContext, useCouponCategoryDeletionBloc] =
    createContext<DeletionBloc<CouponCategoryModel>>();

export const [administratorFormBlocContext, useAdministratorFormBloc] = createContext<AdministratorFormBloc>();

export const [administratorDataTableBlocContext, useAdministratorDataTableBloc] =
    createContext<AdministratorDataTableBloc>();

export const [administratorDeletionContext, useAdministratorDeletionBloc] =
    createContext<DeletionBloc<AdministratorModel>>();

export const [sellerFormBlocContext, useSellerFormBloc] = createContext<SellerFormBloc>();

export const [sellerDataTableBlocContext, useSellerDataTableBloc] = createContext<SellerDataTableBloc>();

export const [sellerDeletionContext, useSellerDeletionBloc] = createContext<DeletionBloc<SellerModel>>();
