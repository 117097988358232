import { Button, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { UploadButton } from '../../../../../core/presentation/components/upload_button/upload_button';
import { useResidueFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { ResidueCategoryModel } from '../../../../residue_categories/domain/models/residue_category_model';
import { ResidueTypeModel } from '../../../../residue_types/domain/models/residue_type_model';
import { ResidueFormBloc, ResidueFormState } from '../../blocs/residue_form_bloc';
import { Logout } from './../../../../authentication/presentation/components/logout/logout';
import { useStyles } from './styles';

const server_url = process.env.REACT_APP_SERVER_URL;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const ResidueForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: ResidueFormBloc = useResidueFormBloc();
    const classes = useStyles();

    useEffect(() => {
        const fillSelectors = (state: ModalState & ResidueFormState, prevState: ModalState & ResidueFormState) => {
            if (!prevState.open && state.open) {
                bloc.fillSelectors();
            }
        };
        const showSnackBar = (state: ModalState & ResidueFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'residue'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.createResidue)
                            enqueueSnackbar(getErrorString('createResidue', state, 'residue'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.updateResidue)
                            enqueueSnackbar(getErrorString('updateResidue', state, 'residue'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.image)
                            enqueueSnackbar(getErrorString('image', state, 'residue'), {
                                variant: 'error',
                            });
                        return;
                    }

                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }
                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.residueCreateSuccessful, { variant: 'success' });
                if (state._type === 'Success' && state.action === 'update')
                    enqueueSnackbar(Language.strings.residueEditSuccessful, { variant: 'success' });
            }
        };
        bloc.subscribe(showSnackBar);
        bloc.subscribe(fillSelectors);

        return () => {
            bloc.unsubscribe(showSnackBar);
            bloc.unsubscribe(fillSelectors);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string | number | File = event.target.value;
        const input = event.target.name;
        if (input === 'image' && event.target.files !== null && event.target.files?.length > 0) {
            value = event.target.files[0];
        }
        bloc.changeInput(input as 'name' | 'barcode' | 'image' | 'residueType' | 'residueCategory', value);
    };

    const handleResidueTypeChanged = (event: React.ChangeEvent<any>, value: ResidueTypeModel | null) => {
        bloc.changeInput('residueType', value || undefined);
    };
    const handleResidueCategoryChanged = (event: React.ChangeEvent<any>, value: ResidueCategoryModel | null) => {
        bloc.changeInput('residueCategory', value || undefined);
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ModalState & ResidueFormState) => {
                const {
                    action,
                    inputs: { residueTypes, residueCategories, residue },
                } = state;
                const title =
                    (action && action === 'read' && Language.strings.residueRead) ||
                    (action === 'create' && Language.strings.residueCreate) ||
                    (action === 'update' && Language.strings.residueUpdate) ||
                    '';

                let imageUrl = '';
                if (residue.image) {
                    if (residue.image instanceof File) {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(residue.image);
                        imageUrl = URL.createObjectURL(residue.image);
                    } else imageUrl = `${server_url}${residue.image}`;
                }
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <FullScreenDialog
                        title={`${title} ${Language.strings.residue}`}
                        open={state.open}
                        disableClose={state.block}
                        actions={
                            state.action !== 'read' ? (
                                <Button
                                    color="inherit"
                                    onClick={handleSubmit}
                                    disabled={state._type === 'Submitting'}
                                    startIcon={<SaveIcon />}
                                >
                                    {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                </Button>
                            ) : undefined
                        }
                        content={
                            <Grid container item xs={12} direction="column" className={`${classes.container}`}>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.residueName}
                                        name="name"
                                        value={residue.name}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.name
                                        }
                                        helperText={getErrorString('name', state, 'residue')}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.residueBarcode}
                                        name="barcode"
                                        value={residue.barcode}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.barcode
                                        }
                                        helperText={getErrorString('barcode', state, 'residue')}
                                    />
                                </Grid>
                                <Grid container className={classes.pb} justify="space-between" spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            style={{ flexGrow: 1 }}
                                            disableCloseOnSelect
                                            options={residueTypes}
                                            getOptionLabel={(option) => (option ? option.name : 'no name')}
                                            value={residue.residueType || null}
                                            renderInput={(params) => (
                                                <TextField {...params} label={Language.strings.residueResidueType} />
                                            )}
                                            limitTags={0}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option?.name || 'no name'}
                                                </React.Fragment>
                                            )}
                                            onChange={handleResidueTypeChanged}
                                        />
                                        {state._type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.residueType ? (
                                            <Typography color="error" variant="caption">
                                                {getErrorString('residueType', state, 'residue')}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            style={{ flexGrow: 1 }}
                                            disableCloseOnSelect
                                            options={residueCategories}
                                            getOptionLabel={(option) => (option ? option.name : 'no name')}
                                            value={residue.residueCategory || null}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={Language.strings.residueResidueCategory}
                                                />
                                            )}
                                            limitTags={0}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option?.name || 'no name'}
                                                </React.Fragment>
                                            )}
                                            onChange={handleResidueCategoryChanged}
                                        />
                                        {state._type === 'Failure' &&
                                        state.failure instanceof ValidationFailure &&
                                        state.failure.fails.residueCategory ? (
                                            <Typography color="error" variant="caption">
                                                {getErrorString('residueCategory', state, 'residue')}
                                            </Typography>
                                        ) : null}
                                    </Grid>
                                </Grid>
                                {action !== 'read' && (
                                    <>
                                        <Grid item className={`${classes.pb} ${classes.center}`}>
                                            <UploadButton
                                                name="image"
                                                color="secondary"
                                                variant="outlined"
                                                onChange={handleInputChanged}
                                                label={Language.strings.residueImage}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {imageUrl !== '' ? (
                                    <Grid item className={`${classes.pb} ${classes.center}`}>
                                        <div className={`${classes.imageViewport} ${classes.center}`}>
                                            <img src={imageUrl} />
                                        </div>
                                    </Grid>
                                ) : null}
                            </Grid>
                        }
                        onClose={() => bloc.close()}
                    />
                );
            }}
        />
    );
};
