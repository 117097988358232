import { UserEntity } from '../../../../core/data/entities/user_entity';
import { UserModelArgs } from '../../../../core/domain/models/user_model';
import { AdministratorModel } from './../../domain/models/administrator_model';

export class AdministratorEntity extends UserEntity {
    _type = 'AdministratorEntity';
    constructor(props: UserModelArgs) {
        super(props);
    }
    toModel(): AdministratorModel {
        return new AdministratorModel(this);
    }

    static fromObject(obj: any): AdministratorEntity {
        return new AdministratorEntity(super.fromObject(obj));
    }
}
