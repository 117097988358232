import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { AdministratorModel } from '../../domain/models/administrator_model';
import { IAdministratorRepository } from '../../domain/repositories/administrator_repository_interface';
import { IAdministratorDataSource } from '../datasources/interfaces/administrator_datasource_interface';
import { AdministratorEntity } from '../entites/administrator_entity';
import { ICreateAdministratorValidations } from '../validations/create_administrator_validations';
import { IToggleAdministratorValidations } from '../validations/toggle_administrator_validations';

export class AdministratorRepository implements IAdministratorRepository {
    datasource: IAdministratorDataSource;

    constructor(datasource: IAdministratorDataSource) {
        this.datasource = datasource;
    }

    async createAdministrator(administrator: AdministratorModel): Promise<AdministratorModel | IFailure> {
        try {
            const entity: AdministratorEntity = await this.datasource.createAdministrator(
                new AdministratorEntity(administrator),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<ICreateAdministratorValidations>(error));
        }
    }
    async toggleAdministrator(administrator: AdministratorModel): Promise<void | IFailure> {
        try {
            return await this.datasource.toggleAdministrator(new AdministratorEntity(administrator));
        } catch (error) {
            return Promise.resolve(errorToFailure<IToggleAdministratorValidations>(error));
        }
    }
    async getAdministrators(
        datatableParams: DataTableParams,
    ): Promise<IFailure | DataTableResponse<AdministratorModel>> {
        try {
            const result: DataTableResponse<AdministratorEntity> = await this.datasource.getAdministrators(
                datatableParams,
            );
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure<IDataTableValidations>(error));
        }
    }
}
