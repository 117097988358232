import { IModel } from '../interfaces/model_interface';

export type UserModelArgs = {
    id: number;
    username: string;
    email: string;
    name: string;
    surname: string;
    identificationNumber: number;
    active: boolean;
};

export class UserModel implements IModel {
    _type = 'UserModel';
    id: number;
    username: string;
    email: string;
    name: string;
    surname: string;
    active: boolean;
    identificationNumber: number;

    constructor({ id, username, email, name, surname, identificationNumber, active }: UserModelArgs) {
        this.id = id;
        this.username = username;
        this.name = name;
        this.email = email;
        this.surname = surname;
        this.identificationNumber = identificationNumber;
        this.active = active;
    }
}
