import { UserEntity } from '../../../../core/data/entities/user_entity';
import { AuthorizationError } from '../../../../core/data/errors/authorization_error';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { AuthenticationFailure } from '../../../../core/domain/failures/authentication_failure';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { UserModel } from '../../../../core/domain/models/user_model';
import { IAuthenticationRepository } from '../../domain/repositories/interfaces/authentication_repository_interface';
import { ChangePasswordParams } from '../../domain/usecases/change_password/change_password';
import { LoginParams } from '../../domain/usecases/login/login';
import {
    AuthenticationResponse,
    IAuthenticationDataSource,
} from '../datasources/interfaces/authentication_datasource_interface';
import { ICacheDataSource } from '../datasources/interfaces/cache_datasource_interface';
import { LoginValidations } from '../validation_interfaces/login_validations';
import { PasswordRecoveryValidations } from '../validation_interfaces/password_recovery_validations';

export class AuthenticationRepository implements IAuthenticationRepository {
    apiDatasource: IAuthenticationDataSource;
    cacheDatasource: ICacheDataSource;

    constructor(apiDatasource: IAuthenticationDataSource, cacheDatasource: ICacheDataSource) {
        this.apiDatasource = apiDatasource;
        this.cacheDatasource = cacheDatasource;
    }
    async changePassword({ password, token }: ChangePasswordParams): Promise<void | IFailure> {
        try {
            return await this.apiDatasource.changePassword({ password, token });
        } catch (error) {
            return Promise.resolve(errorToFailure<PasswordRecoveryValidations>(error));
        }
    }
    async requestPasswordRecovery(email: string): Promise<void | IFailure> {
        try {
            return await this.apiDatasource.requestPasswordRecovery(email);
        } catch (error) {
            return Promise.resolve(errorToFailure<PasswordRecoveryValidations>(error));
        }
    }
    async logout(): Promise<void> {
        return this.cacheDatasource.clear();
    }
    async getAuthenticatedUser(): Promise<UserModel | IFailure> {
        try {
            const cached: UserModel = await this.cacheDatasource.get('user');
            const entity: UserEntity = UserEntity.fromObject(cached);
            return entity.toModel();
        } catch (error) {
            await this.cacheDatasource.clear();
            return Promise.resolve(new AuthenticationFailure('User not in cache'));
        }
    }
    async login({ username, password }: LoginParams): Promise<UserModel | IFailure> {
        try {
            const response: AuthenticationResponse = await this.apiDatasource.login({ username, password });
            const model: UserModel = response.user.toModel();
            await this.cacheDatasource.set('token', response.token);
            await this.cacheDatasource.set('user', response.user);
            return model;
        } catch (error) {
            if (error instanceof AuthorizationError) return Promise.resolve(new AuthenticationFailure(error.message));
            if (error instanceof ValidationError && error.fails['login'])
                return Promise.resolve(new AuthenticationFailure(error.message)); // this is because api responds with 400
            return Promise.resolve(errorToFailure<LoginValidations>(error));
        }
    }
}
