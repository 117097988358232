import { UserModel, UserModelArgs } from '../../../../core/domain/models/user_model';

export type SellerModelArgs = {
    businessName: string;
};

export class SellerModel extends UserModel {
    _type = 'SellerModel';
    businessName: string;
    constructor(props: UserModelArgs & SellerModelArgs) {
        super(props);
        this.businessName = props.businessName;
    }
}
