import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            maxWidth: '800px',
            margin: '0 auto',
            paddingTop: '1rem',
        },
        pb: {
            marginBottom: '1.5rem',
        },
        center: {
            textAlign: 'center',
            margin: '0 auto',
        },
        imageViewport: {
            maxWidth: '300px',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: '10px',
            padding: '1rem',
            marginTop: '1rem',
            ['& img']: {
                width: '100%',
            },
        },
    }),
);
