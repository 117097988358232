import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { CouponCategoryModel } from '../models/coupon_category_model';
import { ICouponCategoryRepository } from '../repositories/coupon_category_repository_interface';

export class GetCouponCategories implements IUseCase<DataTableResponse<CouponCategoryModel>, DataTableParams> {
    couponCategoryRepository: ICouponCategoryRepository;

    constructor(couponCategoryRepository: ICouponCategoryRepository) {
        this.couponCategoryRepository = couponCategoryRepository;
    }

    execute(datatableParams: DataTableParams): Promise<DataTableResponse<CouponCategoryModel> | IFailure> {
        return this.couponCategoryRepository.getCouponCategories(datatableParams);
    }
}
