import { Failure } from '../../../../core/domain/failures/failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { DataTableBloc } from '../../../../core/presentation/bloc/datatable_bloc/datatable_bloc';
import { SellerModel } from '../../domain/models/seller_model';

export class SellerDataTableBloc extends DataTableBloc<SellerModel> {
    getSellersUseCase: IUseCase<DataTableResponse<SellerModel>, DataTableParams>;

    constructor(getSellersUseCase: IUseCase<DataTableResponse<SellerModel>, DataTableParams>) {
        super({ _type: 'Idle', page: 0, size: 10, filters: [], order: undefined, selection: [] });
        Object.setPrototypeOf(this, SellerDataTableBloc.prototype);
        this.getSellersUseCase = getSellersUseCase;
    }

    getSellers = async (): Promise<void> => {
        const { page, size, filters, order, selection } = this.state;
        this.changeState({ _type: 'Loading', page, size, filters, order, selection });
        const result = await this.getSellersUseCase.execute({ page, size, filters, order });
        if (result instanceof Failure)
            return this.changeState({ _type: 'Failure', page, size, filters, order, selection, failure: result });
        this.changeState({
            _type: 'Loaded',
            page,
            size,
            filters,
            order,
            selection: [],
            data: result as DataTableResponse<SellerModel>,
        });
    };
}
