export interface CouponCategoryStrings {
    couponCategory: string;
    couponCategories: string;
    couponCategoryCreate: string;
    couponCategoryRead: string;
    couponCategoryUpdate: string;
    couponCategoryDelete: string;
    couponCategoryName: string;
    couponCategoryCreateSuccessful: string;
    couponCategoryEditSuccessful: string;
    //Errors

    couponCategory_id_exists_error: string;
    couponCategory_id_notin_error: string;
    couponCategory_name_required_error: string;
    couponCategory_createCategory_exist_user_error: string;
    couponCategory_updateCategory_exist_user_error: string;
    couponCategory_deleteCategory_exist_user_error: string;
    couponCategory_createCategory_user_active_error: string;
    couponCategory_updateCategory_user_active_error: string;
    couponCategory_deleteCategory_user_active_error: string;
}

export const couponCategoryStrings: CouponCategoryStrings = {
    couponCategory: 'Categoría de Cupón',
    couponCategories: 'Categorías de Cupón',
    couponCategoryCreate: 'Crear',
    couponCategoryRead: 'Ver',
    couponCategoryUpdate: 'Editar',
    couponCategoryDelete: 'Eliminar',
    couponCategoryName: 'Nombre',
    couponCategoryCreateSuccessful: 'La Categoría de Cupón ha sido creado correctamente',
    couponCategoryEditSuccessful: 'La Categoría de Cupón ha sido editado correctamente',
    //Errors
    couponCategory_name_required_error: 'El Campo Nombre es requerido',

    couponCategory_id_exists_error: 'Error: La Categoría seleccionada no existe.',
    couponCategory_id_notin_error: 'Error: La Categoría seleccionada no debe ser modificada/borrada.',
    couponCategory_createCategory_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    couponCategory_updateCategory_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    couponCategory_deleteCategory_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    couponCategory_createCategory_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    couponCategory_updateCategory_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    couponCategory_deleteCategory_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
};
