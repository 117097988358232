import { AxiosInstance } from 'axios';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { ResidueTypeEntity } from '../entities/residue_type_entity';
import { ICreateResidueTypeValidations } from '../validations/create_residue_type_validations';
import { IDeleteResidueTypeValidations } from '../validations/delete_residue_type_validations';
import { IResidueTypeDataSource } from './interfaces/residue_type_datasource_interface';

export class AxiosResidueTypeDataSource implements IAxiosDataSource, IResidueTypeDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async getResidueTypes(datatableParams: DataTableParams): Promise<DataTableResponse<ResidueTypeEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`residuetypes`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => ResidueTypeEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
    async createResidueType(residueType: ResidueTypeEntity): Promise<ResidueTypeEntity> {
        const formData = this.entityToFormData(residueType);

        try {
            const { data } = await this.axiosInstance.post('residuetypes', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            return ResidueTypeEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<ICreateResidueTypeValidations>(error));
        }
    }
    async updateResidueType(residueType: ResidueTypeEntity): Promise<ResidueTypeEntity> {
        const formData = this.entityToFormData(residueType);
        formData.append('_method', 'PUT');
        try {
            const { data } = await this.axiosInstance.post(`residuetypes/${residueType.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            return ResidueTypeEntity.fromObject(data);
        } catch (error) {
            return Promise.reject(httpToError<IDeleteResidueTypeValidations>(error));
        }
    }
    async deleteResidueType(residueType: ResidueTypeEntity): Promise<void> {
        const { id } = residueType;
        try {
            return await this.axiosInstance.delete(`residuetypes/${id}`);
        } catch (error) {
            return Promise.reject(httpToError<IDeleteResidueTypeValidations>(error));
        }
    }

    /* istanbul ignore next */
    private entityToFormData(residueType: ResidueTypeEntity): FormData {
        const { name, amount, kg, water, energy, co2, km, image, description } = residueType;
        const formData = new FormData();
        formData.append('name', name);
        formData.append('amount', amount.toString());
        formData.append('kg', kg.toString());
        formData.append('water', water.toString());
        formData.append('energy', energy.toString());
        formData.append('co2', co2.toString());
        formData.append('km', km.toString());
        formData.append('image', image);
        formData.append('description', description);
        return formData;
    }
}
