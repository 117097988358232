import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { SellerModel } from '../../domain/models/seller_model';
import { ISellerRepository } from '../../domain/repositories/seller_repository_interface';
import { ISellerDataSource } from '../datasources/interfaces/seller_datasource_interface';
import { SellerEntity } from '../entites/seller_entity';
import { ICreateSellerValidations } from '../validations/create_seller_validations';
import { IToggleSellerValidations } from '../validations/toggle_seller_validations';

export class SellerRepository implements ISellerRepository {
    datasource: ISellerDataSource;

    constructor(datasource: ISellerDataSource) {
        this.datasource = datasource;
    }

    async createSeller(administrator: SellerModel): Promise<SellerModel | IFailure> {
        try {
            const entity: SellerEntity = await this.datasource.createSeller(new SellerEntity(administrator));
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<ICreateSellerValidations>(error));
        }
    }
    async toggleSeller(administrator: SellerModel): Promise<void | IFailure> {
        try {
            return await this.datasource.toggleSeller(new SellerEntity(administrator));
        } catch (error) {
            return Promise.resolve(errorToFailure<IToggleSellerValidations>(error));
        }
    }
    async getSellers(datatableParams: DataTableParams): Promise<IFailure | DataTableResponse<SellerModel>> {
        try {
            const result: DataTableResponse<SellerEntity> = await this.datasource.getSellers(datatableParams);
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure<IDataTableValidations>(error));
        }
    }
}
