export interface ResidueCategoryStrings {
    residueCategory: string;
    residueCategories: string;
    residueCategoryCreate: string;
    residueCategoryRead: string;
    residueCategoryUpdate: string;
    residueCategoryDelete: string;
    residueCategoryName: string;
    residueCategoryCreateSuccessful: string;
    residueCategoryEditSuccessful: string;
    //Errores
    residueCategory_name_required_error: string;

    residueCategory_id_exists_error: string;
    residueCategory_id_notin_error: string;
    residueCategory_createResidueCategory_exist_user_error: string;
    residueCategory_updateResidueCategory_exist_user_error: string;
    residueCategory_deleteResidueCategory_exist_user_error: string;
    residueCategory_createResidueCategory_user_active_error: string;
    residueCategory_updateResidueCategory_user_active_error: string;
    residueCategory_deleteResidueCategory_user_active_error: string;
}

export const residueCategoryStrings: ResidueCategoryStrings = {
    residueCategory: 'Categoría de Residuo',
    residueCategories: 'Categorías de Residuo',
    residueCategoryCreate: 'Crear',
    residueCategoryRead: 'Ver',
    residueCategoryUpdate: 'Editar',
    residueCategoryDelete: 'Eliminar',
    residueCategoryName: 'Nombre',
    residueCategoryCreateSuccessful: 'La Categoría de Residuo ha sido creado correctamente',
    residueCategoryEditSuccessful: 'La Categoría de Residuo ha sido editado correctamente',
    //Errors
    residueCategory_name_required_error: 'El Campo Nombre es requerido',

    residueCategory_id_exists_error: 'Error: La Categoría seleccionada no existe.',
    residueCategory_id_notin_error: 'Error: La Categoría seleccionada no debe ser modificada/borrada.',
    residueCategory_createResidueCategory_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residueCategory_updateResidueCategory_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residueCategory_deleteResidueCategory_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residueCategory_createResidueCategory_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residueCategory_updateResidueCategory_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residueCategory_deleteResidueCategory_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
};
