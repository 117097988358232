import { Button, Grid, TextField } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { AuthorizationFailure } from '../../../../../core/domain/failures/authorization_failure';
import { ValidationFailure } from '../../../../../core/domain/failures/validation_failure';
import { BlocBuilder } from '../../../../../core/presentation/bloc';
import { ModalState } from '../../../../../core/presentation/bloc/modal_bloc/modal_states';
import FullScreenDialog from '../../../../../core/presentation/components/fullscreen_modal/fullscreen_modal';
import { useAdministratorFormBloc } from '../../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../../core/presentation/utils/get_error_string';
import { AdministratorFormBloc, AdministratorFormState } from '../../blocs/administrator_form_bloc';
import { Logout } from './../../../../authentication/presentation/components/logout/logout';
import { useStyles } from './styles';

export const AdministratorForm: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const bloc: AdministratorFormBloc = useAdministratorFormBloc();
    const classes = useStyles();

    useEffect(() => {
        const showSnackBar = (state: ModalState & AdministratorFormState) => {
            if (state.open) {
                if (state._type === 'Failure' && !(state.failure instanceof AuthorizationFailure)) {
                    if (state.failure instanceof ValidationFailure) {
                        if (state.failure.fails.id)
                            enqueueSnackbar(getErrorString('id', state, 'administrator'), {
                                variant: 'error',
                            });
                        if (state.failure.fails.createUserAdministrator)
                            enqueueSnackbar(getErrorString('createUserAdministrator', state, 'administrator'), {
                                variant: 'error',
                            });
                        return;
                    }
                    enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
                }

                if (state._type === 'Success' && state.action === 'create')
                    enqueueSnackbar(Language.strings.administratorCreateSuccessful, { variant: 'success' });
            }
        };
        bloc.subscribe(showSnackBar);

        return () => {
            bloc.unsubscribe(showSnackBar);
        };
    });

    const handleInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        bloc.changeInput(
            event.target.name as 'name' | 'surname' | 'username' | 'identificationNumber' | 'email',
            event.target.value,
        );
    };

    const handleSubmit = () => {
        bloc.submit();
    };
    return (
        <BlocBuilder
            bloc={bloc}
            builder={(state: ModalState & AdministratorFormState) => {
                const { action, inputs } = state;
                const title =
                    (action && action === 'read' && Language.strings.administratorRead) ||
                    (action === 'create' && Language.strings.administratorCreate) ||
                    '';
                if (state._type === 'Failure' && state.failure instanceof AuthorizationFailure)
                    return <Logout error={true} errorType="authorization" />;
                return (
                    <FullScreenDialog
                        title={`${title} ${Language.strings.administrator}`}
                        open={state.open}
                        disableClose={state.block}
                        actions={
                            state.action !== 'read' ? (
                                <Button
                                    color="inherit"
                                    onClick={handleSubmit}
                                    disabled={state._type === 'Submitting'}
                                    startIcon={<SaveIcon />}
                                >
                                    {state._type === 'Submitting' ? Language.strings.saving : Language.strings.save}
                                </Button>
                            ) : undefined
                        }
                        content={
                            <Grid container item xs={12} direction="column" className={`${classes.container}`}>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.administratorSurname}
                                        name="surname"
                                        value={inputs.surname}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.surname
                                        }
                                        helperText={getErrorString('surname', state, 'administrator')}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.administratorName}
                                        name="name"
                                        value={inputs.name}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.name
                                        }
                                        helperText={getErrorString('name', state, 'administrator')}
                                        autoFocus
                                    />
                                </Grid>

                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.administratorUsername}
                                        name="username"
                                        value={inputs.username}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.username
                                        }
                                        helperText={getErrorString('username', state, 'administrator')}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.administratorIdentificationNumber}
                                        name="identificationNumber"
                                        value={inputs.identificationNumber}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        type="number"
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.identificationNumber
                                        }
                                        helperText={getErrorString('identificationNumber', state, 'administrator')}
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item className={classes.pb}>
                                    <TextField
                                        fullWidth
                                        label={Language.strings.administratorEmail}
                                        name="email"
                                        type="email"
                                        value={inputs.email}
                                        onChange={state.action === 'read' ? undefined : handleInputChanged}
                                        error={
                                            state._type === 'Failure' &&
                                            state.failure instanceof ValidationFailure &&
                                            state.failure.fails.email
                                        }
                                        helperText={getErrorString('email', state, 'administrator')}
                                        autoFocus
                                    />
                                </Grid>
                            </Grid>
                        }
                        onClose={() => bloc.close()}
                    />
                );
            }}
        />
    );
};
