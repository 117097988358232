import { useSnackbar } from 'notistack';
import React from 'react';
import di from '../../../../core/app/dependency_injection/di';
import { AuthorizationFailure } from '../../../../core/domain/failures/authorization_failure';
import { Failure } from '../../../../core/domain/failures/failure';
import { ValidationFailure } from '../../../../core/domain/failures/validation_failure';
import { DataTableSelection } from '../../../../core/presentation/components/datatable/datatable';
import { DeletionConfirmationModal } from '../../../../core/presentation/components/deletion_confirmation_modal/deletion_confirmation_modal';
import {
    residueDeletionContext,
    residueFormBlocContext,
    residuesDataTableBlocContext,
} from '../../../../core/presentation/contexts/contexts';
import { Language } from '../../../../core/presentation/strings/LanguageManager';
import getErrorString from '../../../../core/presentation/utils/get_error_string';
import { ResiduesDataTable } from '../components/residue_datatable/residue_datatable';
import { ResidueForm } from '../components/residue_form/residue_form';

export const ResiduesPage = () => {
    const residuesDatatableBloc = di.residueDataTableBloc();
    const residueFormBloc = di.residueFormBloc();
    const residueDeletionBloc = di.residueDeletionBloc();
    const { enqueueSnackbar } = useSnackbar();

    const handleEdit = ({ index, dataIndex }: DataTableSelection) => {
        if (residuesDatatableBloc.state._type === 'Loaded') {
            residueFormBloc.openFor('update', residuesDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleAskConfirmation = ({ index, dataIndex }: DataTableSelection) => {
        if (residuesDatatableBloc.state._type === 'Loaded') {
            residueDeletionBloc.askConfirmation(residuesDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleConfirmDelete = () => {
        residueDeletionBloc.delete();
        residuesDatatableBloc.changeSelection([]);
    };
    const handleConfirmationDenied = () => {
        residueDeletionBloc.denyConfirmation();
    };
    const handleRead = ({ index, dataIndex }: DataTableSelection) => {
        if (residuesDatatableBloc.state._type === 'Loaded') {
            residueFormBloc.openFor('read', residuesDatatableBloc.state.data.data[dataIndex]);
        }
    };
    const handleDeletionFailure = (failure: Failure) => {
        if (failure instanceof ValidationFailure) {
            if (failure.fails.deleteResidue)
                enqueueSnackbar(getErrorString('deleteResidue', { _type: 'Failure', failure }, 'residue'), {
                    variant: 'error',
                });
            if (failure.fails.id)
                enqueueSnackbar(getErrorString('id', { _type: 'Failure', failure }, 'residue'), {
                    variant: 'error',
                });
            if (failure.fails.image)
                enqueueSnackbar(getErrorString('image', { _type: 'Failure', failure }, 'residue'), {
                    variant: 'error',
                });
            return;
        }
        if (failure instanceof AuthorizationFailure) {
            enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
            return;
        }
        enqueueSnackbar(Language.strings.unknown_error, { variant: 'error' });
    };
    const handleDeletionSuccess = () => {
        enqueueSnackbar(Language.strings.deletionSuccess, { variant: 'success' });
    };

    const handleCreate = () => {
        residueFormBloc.openFor('create');
    };

    return (
        <>
            <residueFormBlocContext.Provider value={residueFormBloc}>
                <residueDeletionContext.Provider value={residueDeletionBloc}>
                    <residuesDataTableBlocContext.Provider value={residuesDatatableBloc}>
                        <ResiduesDataTable
                            onRead={handleRead}
                            onEdit={handleEdit}
                            onDelete={handleAskConfirmation}
                            onCreate={handleCreate}
                        />
                    </residuesDataTableBlocContext.Provider>
                    <DeletionConfirmationModal
                        bloc={residueDeletionBloc}
                        onClose={handleConfirmationDenied}
                        onConfirm={handleConfirmDelete}
                        onFailure={handleDeletionFailure}
                        onSuccess={handleDeletionSuccess}
                    />
                </residueDeletionContext.Provider>
                <ResidueForm />
            </residueFormBlocContext.Provider>
        </>
    );
};
