import { AxiosInstance } from 'axios';
import { ValidationError } from '../../../../core/data/errors/validation_error';
import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { httpToError } from '../../../../core/data/utils/http_to_error';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IAxiosDataSource } from '../../../authentication/data/datasources/interfaces/axios_datasource_interface';
import { SellerEntity } from '../entites/seller_entity';
import { ICreateSellerValidations } from '../validations/create_seller_validations';
import { IToggleSellerValidations } from '../validations/toggle_seller_validations';
import { ISellerDataSource } from './interfaces/seller_datasource_interface';

export class AxiosSellerDataSource implements IAxiosDataSource, ISellerDataSource {
    axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    async createSeller(seller: SellerEntity): Promise<SellerEntity> {
        const { surname, name, username, email, identificationNumber, businessName } = seller;
        try {
            const { data } = await this.axiosInstance.post('sellers', {
                surname,
                name,
                username,
                email,
                identification_number: identificationNumber,
                business_name: businessName,
            });
            return SellerEntity.fromObject(data);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                return Promise.reject(
                    new ValidationError<ICreateSellerValidations>({
                        ...error.response.data,
                        businessName: error.response.data.business_name,
                        identificationNumber: error.response.data.identification_number,
                    }),
                );
            }
            return Promise.reject(httpToError<ICreateSellerValidations>(error));
        }
    }
    async toggleSeller(seller: SellerEntity): Promise<void> {
        const { id } = seller;
        try {
            return await this.axiosInstance.post(`users/${id}/status`, { _method: 'PUT' });
        } catch (error) {
            return Promise.reject(httpToError<IToggleSellerValidations>(error));
        }
    }
    async getSellers(datatableParams: DataTableParams): Promise<DataTableResponse<SellerEntity>> {
        try {
            const { size, page, order, filters } = datatableParams;
            const { data } = await this.axiosInstance.get(`sellers`, {
                params: {
                    size,
                    page,
                    filters: JSON.stringify(filters),
                    order: JSON.stringify(order),
                },
            });
            return { data: data.data.map((item: any) => SellerEntity.fromObject(item)), total: data.total };
        } catch (error) {
            return Promise.reject(httpToError<IDataTableValidations>(error));
        }
    }
}
