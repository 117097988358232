import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { ResidueModel } from '../models/residue_model';
import { IResidueRepository } from '../repositories/residue_repository';

export class DeleteResidue implements IUseCase<void, ResidueModel> {
    residueRepository: IResidueRepository;

    constructor(residueRepository: IResidueRepository) {
        this.residueRepository = residueRepository;
    }

    execute(residue: ResidueModel): Promise<void | IFailure> {
        return this.residueRepository.deleteResidue(residue);
    }
}
