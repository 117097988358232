import { IDataTableValidations } from '../../../../core/data/interfaces/datatable_validation_interface';
import { errorToFailure } from '../../../../core/data/utils/error_to_failure';
import { DataTableParams } from '../../../../core/domain/interfaces/datatable_params_interface';
import { DataTableResponse } from '../../../../core/domain/interfaces/datatable_response_interface';
import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { CouponCategoryModel } from '../../domain/models/coupon_category_model';
import { ICouponCategoryRepository } from '../../domain/repositories/coupon_category_repository_interface';
import { ICouponCategoryDataSource } from '../datasources/interfaces/coupon_category_datasource_interface';
import { CouponCategoryEntity } from '../entities/coupon_category_entity';
import { ICreateCouponCategoryValidations } from '../validations/create_coupon_category_validations';
import { IDeleteCouponCategoryValidations } from '../validations/delete_coupon_category_validations';
import { IUpdateCouponCategoryValidations } from '../validations/update_coupon_category_validations';

export class CouponCategoryRepository implements ICouponCategoryRepository {
    datasource: ICouponCategoryDataSource;

    constructor(datasource: ICouponCategoryDataSource) {
        this.datasource = datasource;
    }

    async createCouponCategory(couponCategory: CouponCategoryModel): Promise<CouponCategoryModel | IFailure> {
        try {
            const entity: CouponCategoryEntity = await this.datasource.createCouponCategory(
                new CouponCategoryEntity(couponCategory),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<ICreateCouponCategoryValidations>(error));
        }
    }
    async updateCouponCategory(couponCategory: CouponCategoryModel): Promise<CouponCategoryModel | IFailure> {
        try {
            const entity: CouponCategoryEntity = await this.datasource.updateCouponCategory(
                new CouponCategoryEntity(couponCategory),
            );
            return entity.toModel();
        } catch (error) {
            return Promise.resolve(errorToFailure<IUpdateCouponCategoryValidations>(error));
        }
    }
    async deleteCouponCategory(couponCategory: CouponCategoryModel): Promise<void | IFailure> {
        try {
            return await this.datasource.deleteCouponCategory(new CouponCategoryEntity(couponCategory));
        } catch (error) {
            return Promise.resolve(errorToFailure<IDeleteCouponCategoryValidations>(error));
        }
    }
    async getCouponCategories(
        datatableParams: DataTableParams,
    ): Promise<IFailure | DataTableResponse<CouponCategoryModel>> {
        try {
            const result: DataTableResponse<CouponCategoryEntity> = await this.datasource.getCouponCategories(
                datatableParams,
            );
            return { data: result.data.map((item) => item.toModel()), total: result.total };
        } catch (error) {
            return Promise.resolve(errorToFailure<IDataTableValidations>(error));
        }
    }
}
