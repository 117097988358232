export interface ResidueStrings {
    residue: string;
    residues: string;
    residueCreate: string;
    residueRead: string;
    residueUpdate: string;
    residueDelete: string;
    residueName: string;
    residueBarcode: string;
    residueImage: string;
    residueResidueType: string;
    residueResidueCategory: string;
    residueCreateSuccessful: string;
    residueEditSuccessful: string;
    //Errors
    residue_id_exists_error: string;
    residue_name_required_error: string;
    residue_barcode_required_error: string;
    residue_image_required_error: string;
    residue_image_mimes_error: string;
    residue_image_max_error: string;
    residue_residueCategory_required_error: string;
    residue_residueCategory_exists_error: string;
    residue_residueCategory_numeric_error: string;
    residue_residueType_required_error: string;
    residue_residueType_exists_error: string;
    residue_residueType_numeric_error: string;
    residue_createResidue_exist_user_error: string;
    residue_updateResidue_exist_user_error: string;
    residue_deleteResidue_exist_user_error: string;
    residue_createResidue_user_active_error: string;
    residue_updateResidue_user_active_error: string;
    residue_deleteResidue_user_active_error: string;
}

export const residueStrings: ResidueStrings = {
    residue: 'Residuo',
    residues: 'Residuos',
    residueCreate: 'Crear',
    residueRead: 'Ver',
    residueUpdate: 'Editar',
    residueDelete: 'Eliminar',
    residueName: 'Nombre',
    residueBarcode: 'Código de Barras',
    residueResidueType: 'Tipo de Residuo',
    residueResidueCategory: 'Categoría',
    residueImage: 'Subir/Actualizar Imagen (4:3)',
    residueCreateSuccessful: 'El Tipo de Residuo ha sido creado correctamente',
    residueEditSuccessful: 'El Tipo de Residuo ha sido editado correctamente',

    residue_id_exists_error: 'Error: El residuo seleccionado no existe.',
    residue_name_required_error: 'El campo Nombre es requerido.',
    residue_barcode_required_error: 'El campo Código de Barras es requerido.',
    residue_residueCategory_exists_error: 'La Categoría seleccionada no existe',
    residue_residueCategory_numeric_error: 'La Categoría seleccionado no existe',
    residue_residueType_exists_error: 'El Tipo de Residuo seleccionado no existe',
    residue_residueType_numeric_error: 'El Tipo de Residuo  seleccionado no existe',
    residue_residueCategory_required_error: 'El campo Categoría es requerido.',
    residue_residueType_required_error: 'El campo Tipo de Residuo es requerido.',

    residue_image_required_error: 'Error: Debe cargar una imagen para el residuo',
    residue_image_max_error: 'Error: La imagen debe ser pesar menos de 5 MB',
    residue_image_mimes_error: 'Error: La imagen debe ser png, jpg o jpeg',
    residue_createResidue_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residue_updateResidue_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residue_deleteResidue_exist_user_error: 'Error: Su cuenta de administrador no existe.',
    residue_createResidue_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residue_updateResidue_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
    residue_deleteResidue_user_active_error: 'Error: Su cuenta de administrador está inactiva.',
};
