import { IFailure } from '../../../../core/domain/interfaces/failure_interface';
import { IUseCase } from '../../../../core/domain/interfaces/usecase_interface';
import { CouponCategoryModel } from '../models/coupon_category_model';
import { ICouponCategoryRepository } from '../repositories/coupon_category_repository_interface';

export class UpdateCouponCategory implements IUseCase<CouponCategoryModel, CouponCategoryModel> {
    couponCategoryRepository: ICouponCategoryRepository;

    constructor(couponCategoryRepository: ICouponCategoryRepository) {
        this.couponCategoryRepository = couponCategoryRepository;
    }

    execute(couponCategory: CouponCategoryModel): Promise<CouponCategoryModel | IFailure> {
        return this.couponCategoryRepository.updateCouponCategory(couponCategory);
    }
}
